<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card class="dialog-q-card">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <div class="content-title">
                Поиск оборудования
            </div>
            <q-separator />
            <div style="flex-grow: 0;">
                <q-card-section>
                    <div class="dialog-common-grid-col-2">
                        <SelectFilter
                            v-model="toolSetNameId"
                            :options="toolsSetsNamesCodes"
                            label="Вид комплекта оборудования"
                            optionLabel="name"
                            clearable
                            outlined
                            dense
                        />
                        <q-select
                            :modelValue="toolKindId"
                            outlined
                            dense
                            clearable
                            :options="toolsKindsFiltered"
                            optionValue="id"
                            optionLabel="title"
                            mapOptions
                            emitValue
                            label="Вид оборудования"
                            @update:modelValue="setToolKindId"
                        />
                        <q-select
                            v-model="manufacturerId"
                            outlined
                            dense
                            clearable
                            :options="manufacturers"
                            optionValue="id"
                            optionLabel="title"
                            mapOptions
                            emitValue
                            label="Производитель оборудования"
                        />
                        <q-input
                            v-model="toolModel"
                            outlined
                            dense
                            clearable
                            label="Наименование"
                        />
                        <q-input
                            v-model="toolSerialNumber"
                            outlined
                            dense
                            clearable
                            label="Серийный номер"
                        />
                        <q-input
                            v-model="toolInventarNumber"
                            outlined
                            dense
                            clearable
                            label="Инвентарный номер"
                        />
                    </div>
                </q-card-section>

                <div class="pagination">
                    <q-pagination
                        v-model="currentPage"
                        color="blue-grey-7"
                        :max="toolsPagesCount"
                        :maxPages="6"
                        :boundaryNumbers="true"
                    />
                </div>
            </div>

            <div class="search-tool-grid">
                <!-- header -->
                <div class="cell cell--header">
                    №
                </div>
                <div class="cell cell--header">
                    Вид оборудования
                </div>
                <div class="cell cell--header">
                    Производитель
                </div>
                <div class="cell cell--header">
                    Наименование
                </div>
                <div class="cell cell--header">
                    Инвентарный номер
                </div>
                <!-- data -->
                <div
                    v-for="(tool, toolIndex) in toolsFiltered"
                    :key="toolIndex"
                    class="row-wrapper"
                    @click="onOkClick(tool)"
                >
                    <div class="cell cell__center">
                        {{ (toolsPageNumber - 1) * 20 + 1 + toolIndex }}
                    </div>
                    <div class="cell">
                        {{ getToolKindName(tool.tool_kind_id) }}
                    </div>
                    <div class="cell">
                        {{ getManufacturerName(tool.manufacturer_id) }}
                    </div>
                    <div class="cell">
                        {{ getToolModelName(tool.model_id) }}
                    </div>
                    <div class="cell">
                        {{ tool.inventar_number }}
                    </div>
                </div>
            </div>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";

function generateProperty(property) {
    return {
        get() {
            return this.toolsFilter[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.replace(/[\t \n\r]/g, "")
                : value;

            this.$store.dispatch(
                "setToolsFilter",
                {
                    ...this.toolsFilter,
                    [property]: v,
                },
            );
        },
    };
}

export default {
    name: "DialogSearchTool",
    components: {
        SelectFilter,
    },
    props: {
        toolsWithSoft: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["ok"],
    data() {
        return {
            toolId: null,
            toolKindId: null,
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            toolsKinds: "toolsKinds",
            toolsFilter: "toolsFilter",
            toolsFiltered: "toolsFiltered",
            getToolKindName: "getToolKindName",
            getManufacturerName: "getManufacturerName",
            getToolModelName: "getToolModelName",
            toolsPagesCount: "toolsPagesCount",
            toolsPageNumber: "toolsPageNumber",
            toolsSetsNamesCodes: "toolsSetsNamesCodes",
            manufacturers: "manufacturers",
        }),
        toolsKindsFiltered() {
            return this.toolsWithSoft
                ? this.toolsKinds.filter((tk) => tk.soft_updatable)
                : this.toolsKinds;
        },
        toolSetNameId: generateProperty("tool_set_name_id"),
        manufacturerId: generateProperty("manufacturer_id"),
        toolSerialNumber: generateProperty("serial_number"),
        toolInventarNumber: generateProperty("inventar_number"),
        toolModel: generateProperty("tool_model"),
        currentPage: {
            get() {
                return this.toolsPageNumber;
            },
            async set(value) {
                this.showSpinner = true;
                await this.$store.dispatch("setToolsPageNumber", value);
                this.showSpinner = false;
            },
        },
    },
    methods: {
        show() {
            this.$store.dispatch(
                "setToolsFilter",
                {
                    ...this.toolsFilter,
                    tool_kind_ids: this.toolsKindsFiltered.map((k) => k.id),
                },
            );
            this.$refs.dialog.show();
            this.currentPage = 1;
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick(tool) {
            this.$emit("ok", tool);

            this.hide();
        },
        setToolKindId(value) {
            this.toolKindId = value;
            this.$store.dispatch(
                "setToolsFilter",
                {
                    ...this.toolsFilter,
                    tool_kind_ids: value
                        ? [value]
                        : this.toolsKindsFiltered.map((k) => k.id),
                },
            );
        },
    },
};
</script>
