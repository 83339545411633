<template>
    <q-dialog
        v-model="showFilter"
        position="top"
    >
        <q-card style="width: 70%; max-width: 70%;">
            <div class="filter-grid--tool">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="cell-span-col-2"
                />
                <q-select
                    v-model="unitsIds"
                    outlined
                    dense
                    :options="unitsFiltered"
                    optionValue="id"
                    optionLabel="name"
                    mapOptions
                    emitValue
                    label="Объект"
                    multiple
                    clearable
                    class="cell-span-col-2"
                    @clear="() => unitsIds = []"
                >
                    <template #option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section>
                                <q-item-label>
                                    {{ opt.name }}
                                </q-item-label>
                                <q-item-label caption>
                                    {{ opt.address }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox
                                    :modelValue="selected"
                                    @update:model-value="toggleOption(opt)"
                                />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
                <q-select
                    v-model="requestPerformerKind"
                    outlined
                    dense
                    :options="performersKinds"
                    label="Тип исполнителя"
                    emitValue
                    mapOptions
                    clearable
                />
                <q-select
                    v-model="engineerId"
                    outlined
                    dense
                    clearable
                    :options="engineers"
                    optionValue="id"
                    :optionLabel="opt => `${opt.surname} ${opt.name} ${opt.patronymic}`"
                    mapOptions
                    emitValue
                    label="Исполнитель"
                >
                    <template #option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section>
                                <q-item-label>
                                    {{ scope.opt.surname }}
                                    {{ scope.opt.name }}
                                    {{ scope.opt.patronymic }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
                <q-input
                    v-model="requestNumber"
                    outlined
                    dense
                    clearable
                    type="number"
                    label="Номер заявки"
                />
                <q-select
                    v-model="reqKindIdArr"
                    outlined
                    dense
                    :options="requestKindsWithNull"
                    optionValue="id"
                    optionLabel="title"
                    label="Тип заявки"
                    multiple
                    emitValue
                    mapOptions
                    clearable
                    @clear="() => reqKindIdArr = []"
                >
                    <template #option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section>
                                {{ opt.title }}
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox
                                    :modelValue="selected"
                                    @update:model-value="toggleOption(opt)"
                                />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
                <q-input
                    v-model="hotLineNumber"
                    outlined
                    dense
                    clearable
                    type="number"
                    label="Номер на ГЛ"
                />
                <q-select
                    v-model="reqStatusIdArr"
                    outlined
                    dense
                    :options="REQUEST_STATUSES"
                    optionValue="id"
                    optionLabel="title"
                    label="Статус заявки"
                    multiple
                    emitValue
                    mapOptions
                    clearable
                    @clear="() => reqStatusIdArr = []"
                >
                    <template #option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section>
                                {{ opt.title }}
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox
                                    :modelValue="selected"
                                    @update:model-value="toggleOption(opt)"
                                />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
                <q-select
                    v-model="resultType"
                    outlined
                    dense
                    :options="PERFORM_TYPES"
                    label="Вид исполнения"
                    emitValue
                    mapOptions
                    clearable
                />
                <div />
                <PoeDatePicker
                    v-model="dateBeg"
                    labelTitle="Заявки С"
                    emptyDateAllowed
                    classOuter=""
                    :doValidate="false"
                />
                <PoeDatePicker
                    v-model="dateEnd"
                    labelTitle="Заявки ПО"
                    emptyDateAllowed
                    classOuter=""
                    :doValidate="false"
                />
                <div>
                    <q-toggle
                        v-model="notSignedDocs"
                        label="Заявки с неподписанными документами (ЭЦП)"
                    />
                </div>
                <FilterRequestsRepair v-if="reqKindIdArr.includes(REQUEST_KINDS_VALUES.REPAIR_HARD)" />
                <FilterRequestsSoft v-if="reqKindIdArr.includes(REQUEST_KINDS_VALUES.SOFT_COMMON) ||
                    reqKindIdArr.includes(REQUEST_KINDS_VALUES.SOFT_SPECIAL)" />
                <FilterRequestsLearning v-if="reqKindIdArr.includes(REQUEST_KINDS_VALUES.LEARNING)" />
            </div>
            <div class="filter-controls">
                <q-btn
                    label="Очистить"
                    @click="$store.dispatch('cleanReqsFilter')"
                />
                <q-btn
                    label="Закрыть"
                    @click="showFilter = false"
                />
            </div>
        </q-card>
    </q-dialog>

    <q-btn
        round
        :icon="matSearch"
        @click="showFilter = !showFilter"
    />
</template>

<script>
import { mapGetters } from "vuex";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import {
    PERFORM_KIND,
    REQUEST_KINDS_VALUES,
    REQUEST_KINDS,
    REQUEST_STATUSES,
    RIGHTS_CONSTANTS,
    PERFORM_TYPES,
} from "@/store/constants";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import FilterRequestsRepair from "./FilterRequestsRepair.vue";
import FilterRequestsLearning from "./FilterRequestsLearning.vue";
import FilterRequestsSoft from "./FilterRequestsSoft.vue";

function generateProperty(property) {
    return {
        get() {
            return this.filterRequests[property];
        },
        set(value) {
            this.$store.dispatch(
                "setFilterRequests",
                {
                    ...this.filterRequests,
                    [property]: value,
                },
            );
        },
    };
}

export default {
    name: "FilterRequests",
    components: {
        PoeDatePicker,
        SelectFilter,
        FilterRequestsRepair,
        FilterRequestsLearning,
        FilterRequestsSoft,
    },
    setup() {
        return {
            REQUEST_KINDS_VALUES,
            REQUEST_STATUSES,
            PERFORM_TYPES,

            performersKinds: [
                {
                    label: "Все",
                    value: null,
                },
                {
                    label: "Головной исполнитель",
                    value: PERFORM_KIND.SENIOR,
                },
                {
                    label: "Филиал",
                    value: PERFORM_KIND.FILIAL,
                },
            ],
        };
    },
    data() {
        return {
            showFilter: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            unitsFull: "unitsFull",
            filterRequests: "filterRequests",
            currentUser: "currentUser",
            engineers: "engineers",
        }),
        requestKindsWithNull() {
            return [
                ...REQUEST_KINDS,
                {
                    id: null,
                    title: "Неизвестный тип",
                },
            ];
        },
        unitsFiltered() {
            const uf = this.regionId
                ? this.unitsFull.filter((u) => u.region_id === this.regionId)
                : this.unitsFull;

            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)
                ? uf
                : uf.filter((u) => this.currentUser.units.includes(u.id));
        },
        regionId: generateProperty("region_id"),
        requestPerformerKind: generateProperty("request_performer_kind"),
        requestNumber: generateProperty("number"),
        unitsIds: generateProperty("units_ids"),
        engineerId: generateProperty("engineer_id"),
        reqKindIdArr: generateProperty("request_kinds_ids"),
        reqStatusIdArr: generateProperty("request_statuses_ids"),
        dateBeg: generateProperty("date_beg"),
        dateEnd: generateProperty("date_end"),
        hotLineNumber: generateProperty("hotlinegas_number"),
        notSignedDocs: generateProperty("unsigned_docs"),
        resultType: generateProperty("result_type"),
    },
    watch: {
        regionId(v) {
            if (v) {
                this.unitsIds = [];
            }
        },
    },
};
</script>
