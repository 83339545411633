<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Модели учетных единиц
            ({{ toolsModels.length }})
            <q-space />
            <FilterToolsModels />
        </div>
        <!--
        TODO: restore
        <div class="pagination">
            <q-pagination
                v-model="currentPage"
                color="blue-grey-7"
                :max="toolsPagesCount"
                :maxPages="6"
                :boundaryNumbers="true"
            />
        </div>
        -->
        <div class="content-body tools-models-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Вид оборудования
            </div>
            <div class="cell cell-header cell-center">
                Производитель
            </div>
            <div class="cell cell-header cell-center">
                Наименование
            </div>
            <div class="cell cell-header cell-center">
                Характеристики (тип / модель)
            </div>
            <div class="cell cell-header cell-center">
                Документов / Изображений
            </div>
            <div class="cell cell-header">
                <q-btn
                    round
                    :icon="matAdd"
                    color="primary"
                    size="0.7rem"
                    @click="startEdit(null)"
                />
            </div>
            <!-- data -->
            <ToolModelsRow
                v-for="(tModel, toolIndex) in toolsModels"
                :key="toolIndex"
                :toolIndex="toolIndex"
                :toolModel="tModel"
                @startEdit="startEdit"
                @deleteItem="deleteItem"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import FilterToolsModels from "@/components/Filters/FilterToolsModels.vue";
import DialogEditToolModel from "@/components/Dialogs/DialogEditToolKind/DialogEditToolModel.vue";
import ToolModelsRow from "./ToolsModelsRow.vue";

export default {
    name: "ToolsModels",
    components: {
        FilterToolsModels,
        ToolModelsRow,
    },
    data() {
        return {
            toolModel: {},
        };
    },
    computed: {
        ...mapGetters({
            toolsModels: "toolsModels",
            getToolKindName: "getToolKindName",
            getManufacturerName: "getManufacturerName",
            // toolsPagesCount: "toolsPagesCount",
            // toolsPageNumber: "toolsPageNumber",
        }),
    },
    methods: {
        startEdit(index) {
            // form toolModel object
            this.$q.dialog({
                component: DialogEditToolModel,
                componentProps: {
                    itemForEdit: index === null
                        ? {}
                        : {
                            ...this.toolsModels[index],
                        },
                },
            })
                .onOk(({
                    toolModel,
                    toolKindTraitsValues,
                    filesListDocs,
                    filesListPictures,
                }) => {
                    this.$store
                        .dispatch(
                            "saveToolModel",
                            {
                                toolModel: {
                                    ...toolModel,
                                    traits: toolKindTraitsValues,
                                },
                                filesListDocs,
                                filesListPictures,
                            },
                        )
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((message) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message,
                            });
                        });
                });
        },
        deleteItem(index) {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить модель учетной единицы
                        "${this.toolsModels[index].model}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteToolModel", this.toolsModels[index].id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
