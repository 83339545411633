<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card class="dialog-card">
            <div class="text-h6 dialog-title">
                Исполнитель ГЛ
            </div>

            <q-tabs
                :modelValue="tabCurrent"
                dense
                activeColor="primary"
                indicatorColor="primary"
                align="justify"
                keepAlive
                @update:modelValue="tabChange"
            >
                <q-tab
                    name="info"
                    label="Общее"
                />
                <q-tab
                    name="roles"
                    label="Роли"
                />
                <q-tab
                    name="programs"
                    label="ПО"
                />
            </q-tabs>

            <q-separator />

            <q-tab-panels
                v-model="tabCurrent"
                animated
            >
                <q-tab-panel name="info">
                    <q-card-section class="scroll no-padding dialog-section">
                        <div class="dialog-common-grid-col-2">
                            <q-input
                                ref="refSurname"
                                v-model="userHotline.surname"
                                outlined
                                dense
                                label="Исполнитель"
                                class="cell cell-span-col-2"
                                hideBottomSpace
                                :rules="[val => val && val.length >= 1 || 'Введите исполнителя']"
                                :readonly="!canBeEdited"
                            />
                            <q-input
                                v-model="userHotline.phone"
                                outlined
                                dense
                                label="Телефон"
                                :readonly="!canBeEdited"
                            />
                            <q-input
                                v-model="userHotline.email"
                                outlined
                                dense
                                label="Email"
                                :readonly="!canBeEdited"
                                hideBottomSpace
                                :rules="[checkEmail]"
                            />
                            <q-input
                                v-model="userHotline.due_order"
                                outlined
                                dense
                                autogrow
                                label="Примечание"
                                :readonly="!canBeEdited"
                                class="cell cell-span-col-2"
                            />
                            <q-input
                                ref="refLogin"
                                v-model="userHotline.login"
                                outlined
                                dense
                                label="Login"
                                hideBottomSpace
                                :readonly="!canBeEdited"
                                :rules="[checkLogin]"
                            />
                            <q-input
                                v-model="userHotline.password"
                                outlined
                                dense
                                label="Password"
                                :readonly="!canBeEdited"
                            >
                                <template #after>
                                    <q-btn
                                        round
                                        size="sm"
                                        :icon="matAutorenew"
                                        class="q-mr-sm"
                                        @click="generatePassword"
                                    />
                                </template>
                            </q-input>
                        </div>
                    </q-card-section>
                </q-tab-panel>
                <q-tab-panel name="roles">
                    <q-card-section class="scroll no-padding dialog-section">
                        <div class="simple-three-grid">
                            <!-- header -->
                            <div class="cell cell-header cell-center">
                                №
                            </div>
                            <div class="cell cell-header cell-center">
                                Наименование роли
                            </div>
                            <div class="cell cell-header cell-center">
                                <q-btn
                                    round
                                    :icon="matAdd"
                                    size="sm"
                                    color="primary"
                                >
                                    <q-tooltip
                                        :delay="300"
                                        anchor="top right"
                                        self="bottom middle"
                                    >
                                        Добавить роль
                                    </q-tooltip>

                                    <q-menu
                                        autoClose
                                        anchor="top left"
                                        self="bottom right"
                                    >
                                        <q-list style="min-width: 100px">
                                            <template
                                                v-for="role in rolesFilter"
                                                :key="role.id"
                                            >
                                                <q-item
                                                    clickable
                                                    @click="addUserRole(role.id)"
                                                >
                                                    <q-item-section>
                                                        {{ roleName(role.id) }}
                                                    </q-item-section>
                                                </q-item>
                                            </template>
                                        </q-list>
                                    </q-menu>
                                </q-btn>
                            </div>
                            <!-- data -->
                            <div
                                v-for="(role, roleIndex) in userHotline.users_roles"
                                :key="roleIndex"
                                class="row-wrapper"
                            >
                                <div class="cell cell-center">
                                    {{ roleIndex + 1 }}
                                </div>
                                <div class="cell">
                                    {{ roleName(role.role_id) }}
                                </div>
                                <div class="cell cell-center">
                                    <q-btn
                                        round
                                        :icon="matDelete"
                                        size="sm"
                                        @click="delUserRole(role.role_id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </q-card-section>
                </q-tab-panel>
                <q-tab-panel name="programs">
                    <q-card-section class="scroll no-padding dialog-section">
                        <div class="simple-three-grid">
                            <!-- header -->
                            <div class="cell cell-header cell-center">
                                №
                            </div>
                            <div class="cell cell-header cell-center">
                                ПО
                            </div>
                            <div class="cell cell-header cell-center">
                                <q-btn
                                    v-if="canBeEdited"
                                    round
                                    :icon="matAdd"
                                    size="0.6rem"
                                    color="primary"
                                >
                                    <q-tooltip
                                        :delay="300"
                                        anchor="top right"
                                        self="bottom middle"
                                    >
                                        Добавить ПО
                                    </q-tooltip>

                                    <q-menu
                                        autoClose
                                        anchor="top left"
                                        self="bottom right"
                                    >
                                        <q-list style="min-width: 100px">
                                            <template
                                                v-for="program in programsSpecial"
                                                :key="program.id"
                                            >
                                                <q-item
                                                    clickable
                                                    @click="() => addUserProgram(program.id)"
                                                >
                                                    <q-item-section>
                                                        {{ program.title }}
                                                    </q-item-section>
                                                </q-item>
                                            </template>
                                        </q-list>
                                    </q-menu>
                                </q-btn>
                            </div>
                            <!-- data -->
                            <div
                                v-for="(program, index) in userPrograms"
                                :key="index"
                                class="row-wrapper"
                            >
                                <div class="cell cell-center">
                                    {{ index + 1 }}
                                </div>
                                <div class="cell">
                                    {{ program.title }}
                                </div>
                                <div class="cell cell-center">
                                    <q-btn
                                        v-if="canBeEdited"
                                        round
                                        :icon="matDelete"
                                        size="0.6rem"
                                        @click="removeUserProgram(program.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </q-card-section>
                </q-tab-panel>
            </q-tab-panels>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-if="canBeEdited"
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    flat
                    :label="canBeEdited ? 'Отменить' : 'Закрыть'"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";

export default {
    name: "DialogEditUserHotline",
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userHotline: {
                name: "HotlineWorker",
                users_roles: [],
            },
            tabCurrent: "info",
            userPrograms: [],
        };
    },
    computed: {
        ...mapGetters({
            programs: "programs",
            currentUser: "currentUser",
            roles: "roles",
            roleName: "roleName",
        }),
        programsSpecial() {
            return this.programs
                .filter((p) => p.type === 2)
                .filter((p) => !this.userPrograms.map((up) => up.id).includes(p.id));
        },
        canBeEdited() {
            return this.currentUser.rightsObject.RIGHT_USERS_HL === RIGHTS_CONSTANTS.FULL;
        },
        rolesFilter() {
            return this.roles
                .filter((r) => r.role_is_hotline)
                .filter((r) => r.access_level <= this.currentUser.accessLevel)
                .filter((r) => !this.userHotline.users_roles.map((ur) => ur.role_id).includes(r.id));
        },
    },
    methods: {
        checkErrorsInfo() {
            const hasErrors = Object.keys(this.$refs).reduce(
                (acc, key) => {
                    if (Object.prototype.hasOwnProperty.call(this.$refs[key], "validate")) {
                        this.$refs[key].validate();
                        return acc || this.$refs[key].hasError;
                    }
                    return acc;
                }, false,
            );

            return hasErrors;
        },
        checkLogin(login) {
            if (login.length < 3) {
                return "Введите login (минимум 3 символа)";
            }

            return /^[\w_]+$/.test(login) || "Только латинские буквы и цифры";
        },
        checkEmail(email) {
            return /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(email) || "Укажите email";
        },
        generatePassword() {
            const letters = [
                "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
                "abcdefghijklmnopqrstuvwxyz",
                "1234567890",
            ].join("");

            let retVal = "";

            for (let i = 0; i < 8; i++) {
                retVal = `${retVal}${letters.at(Math.floor(Math.random() * letters.length))}`;
            }

            this.userHotline.password = retVal;
        },
        addUserProgram(programId) {
            const program = this.programs.find((p) => p.id === programId);
            this.userPrograms.push(program);
        },
        addUserRole(roleId) {
            if (!this.userHotline.users_roles.map((ur) => ur.role_id).includes(roleId)) {
                this.userHotline.users_roles.push({ role_id: roleId });
            }
        },
        delUserRole(roleId) {
            this.userHotline.users_roles = this.userHotline.users_roles.filter((ur) => ur.role_id !== roleId);
        },
        removeUserProgram(programId) {
            const pInd = this.userPrograms.findIndex((p) => p.id === programId);
            if (pInd >= 0) {
                this.userPrograms.splice(pInd, 1);
            }
        },
        tabChange(value) {
            if (this.$refs.refSurname && this.checkErrorsInfo()) {
                this.tabCurrent = "info";
            }
            else {
                this.tabCurrent = value;
            }
        },
        show() {
            this.userHotline = {
                ...this.userHotline,
                ...this.user,
                password: "",
                hotline_worker: true,
            };
            this.userPrograms = this.user.users_programs
                .map((pid) => (this.programs.find((p) => p.id === pid.program_id)) || null)
                .filter((p) => p)
                .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
            this.tabCurrent = "info";
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            if (this.tabCurrent === "info" && this.checkErrorsInfo()) return;

            this.$store.dispatch(
                "saveUser",
                {
                    ...this.userHotline,
                    users_programs: this.userPrograms,
                },
            )
                .then((message) => {
                    this.$q.notify({
                        type: "notify-success",
                        message,
                    });
                    this.hide();
                })
                .catch((message) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message,
                    });
                });
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
