import {
    ACCESS_LEVELS_VALUES,
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
    OPTIONS_VIEW_BEFORE_VALUES,
    TOOLS_STATES_VALUES,
    PERFORM_TYPES_IDS,
    REPAIRS_TYPES_VALUES,
    REPAIRS_TYPES,
    REPAIRS_RESULTS_IDS,
    INNER_REQS_STATES,
    RIGHTS_CONSTANTS,
    RIGHTS,
    PERFORM_KIND,
    CONTACT_KIND,
} from "../../../poeso-server/app/config/constants";

export const siteHost = process.env.VUE_APP_SERVER_BASE_URL
    ? process.env.VUE_APP_SERVER_BASE_URL
    : `${window.location.protocol}//${window.location.host}`;

export const DATE_LOCALE = {
    /* starting with Sunday */
    days: "Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота".split("_"),
    daysShort: "Вск_Пнд_Втр_Срд_Чтв_Птн_Сбб".split("_"),
    months: [
        "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь",
    ],
    monthsShort: "Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек".split("_"),
    firstDayOfWeek: 1,
};

export const DATE_LOCALE_2 = {
    /* starting with Sunday */
    days: "Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота".split("_"),
    daysShort: "Вск_Пнд_Втр_Срд_Чтв_Птн_Сбб".split("_"),
    months: [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
    ],
    monthsShort: "Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек".split("_"),
    firstDayOfWeek: 1,
};

export {
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
    OPTIONS_VIEW_BEFORE_VALUES,
    TOOLS_STATES_VALUES,
    REPAIRS_TYPES_VALUES,
    REPAIRS_TYPES,
    INNER_REQS_STATES,
    RIGHTS_CONSTANTS,
    RIGHTS,
    PERFORM_KIND,
    CONTACT_KIND,
    REPAIRS_RESULTS_IDS,
};

export const ACCESS_LEVELS = [
    {
        value: ACCESS_LEVELS_VALUES.FULL,
        label: "Полный",
    },
    {
        value: ACCESS_LEVELS_VALUES.REGION,
        label: "Регион",
    },
    {
        value: ACCESS_LEVELS_VALUES.UNIT,
        label: "Объект",
    },
    {
        value: ACCESS_LEVELS_VALUES.OWN,
        label: "Персональный",
    },
];

export const TOOLS_AGES = [
    { value: null, title: "Все" },
    { value: "count_old", title: "Старше 5-ти лет" },
    { value: "count_new", title: "Младше 5-ти лет" },
];

export const PROGRAM_TYPES_VALUES = {
    SOFT_COMMON: 1,
    SOFT_SPECIAL: 2,
};

export const PROGRAM_TYPES = [
    {
        value: PROGRAM_TYPES_VALUES.SOFT_COMMON,
        label: "ОПО",
    },
    {
        value: PROGRAM_TYPES_VALUES.SOFT_SPECIAL,
        label: "СПО",
    },
];

// request statuses
export const REQUEST_STATUSES = [
    { id: REQUEST_STATUS_VALUES.WAITING_ENGINEER, title: "Ожидание исполнителя" },
    { id: REQUEST_STATUS_VALUES.PERFORMING, title: "На исполнении" },
    { id: REQUEST_STATUS_VALUES.PERFORMED, title: "Исполнена" },
    { id: REQUEST_STATUS_VALUES.CLOSED, title: "Закрыта" },
    { id: REQUEST_STATUS_VALUES.CANCELED, title: "Отменена" },
    { id: REQUEST_STATUS_VALUES.UNDEFINED, title: "Неустановлено" },
    { id: REQUEST_STATUS_VALUES.HOT_LINE_WAITING, title: "Ожидание исполнителя ГЛ" },
    { id: REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING, title: "На исполнении техподдержки ГЛ" },
    { id: REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED, title: "Исполнено техподдержка ГЛ" },
];

// request kinds

export const REQUEST_KINDS = [
    {
        id: REQUEST_KINDS_VALUES.SOFT_SPECIAL,
        title: "Заявка по вопросам СПО",
    },
    {
        id: REQUEST_KINDS_VALUES.SOFT_COMMON,
        title: "Заявка по вопросам ОПО",
    },
    {
        id: REQUEST_KINDS_VALUES.REPAIR_HARD,
        title: "Заявка на ремонт оборудования",
    },
    /*
    {
        id: REQUEST_KINDS_VALUES.LEARNING,
        title: "Заявка на обучение пользователей",
    },
    */
    {
        id: REQUEST_KINDS_VALUES.RESTORE,
        title: "Заявка на восстановление системы",
    },
    {
        id: REQUEST_KINDS_VALUES.CONSULTATION,
        title: "Заявка на консультацию",
    },
    {
        id: REQUEST_KINDS_VALUES.PROPOSE,
        title: "Предложение по модификации",
    },
    /*
    {
        id: REQUEST_KINDS_VALUES.TASK,
        title: "Поручение",
    },
    */
    {
        id: REQUEST_KINDS_VALUES.MISTAKE,
        title: "Ошибочно поданная заявка",
    },
    {
        id: REQUEST_KINDS_VALUES.REVOKE,
        title: "Отозванная заявка",
    },
];

export const OPTIONS_VEIW_BEFORE = [
    { value: OPTIONS_VIEW_BEFORE_VALUES.REPAIR_WAITING, label: "Осмотр проведен, ожидание ремонта" },
    { value: OPTIONS_VIEW_BEFORE_VALUES.TO_DISMISS, label: "Осмотр проведен, списание" },
    { value: OPTIONS_VIEW_BEFORE_VALUES.NOT_COMPLETED, label: "Осмотр не проведен" },
];

export const OPTIONS_VIEW_AFTER = [
    { value: 1, label: "Осмотр проведен" },
    { value: 2, label: "Осмотр не проведен" },
];

export const PERFORM_TYPES = [
    { value: PERFORM_TYPES_IDS.WITHOUT_VISIT, label: "Исполнено без выезда" },
    { value: PERFORM_TYPES_IDS.WITH_VISIT, label: "Исполнено с выездом" },
];

export const REPAIRS_RESULTS = [
    { value: REPAIRS_RESULTS_IDS.FULL, label: "Ремонт выполнен полностью" },
    { value: REPAIRS_RESULTS_IDS.PART, label: "Ремонт выполнен частично" },
    { value: REPAIRS_RESULTS_IDS.NONE, label: "Ремонт не выполнен" },
];

export const PERFORM_RESULTS_IDS = {
    FULFILED: 1,
    FULFILED_PARTIALLY: 2,
    NOT_FULFILED: 3,
    MOVED_HOTLINE: 4,
    FULFILED_HOTLINE: 5,
};

export const PERFORM_RESULTS_PO_SPECIAL = [
    { value: PERFORM_RESULTS_IDS.FULFILED, label: "Исполнено" },
    { value: PERFORM_RESULTS_IDS.FULFILED_PARTIALLY, label: "Частично исполнено" },
    { value: PERFORM_RESULTS_IDS.NOT_FULFILED, label: "Не исполнено" },
    { value: PERFORM_RESULTS_IDS.MOVED_HOTLINE, label: "Передано на 'Техподдержку ГЛ'" },
    { value: PERFORM_RESULTS_IDS.FULFILED_HOTLINE, label: "Исполнено 'Техподдержка ГЛ'" },
];

export const PERFORM_RESULTS_PO_COMMON = [
    { value: PERFORM_RESULTS_IDS.FULFILED, label: "Исполнено" },
    { value: PERFORM_RESULTS_IDS.FULFILED_PARTIALLY, label: "Частично исполнено" },
    { value: PERFORM_RESULTS_IDS.NOT_FULFILED, label: "Не исполнено" },
];

// types of performing results
export const PERFORM_RESULT_TYPE = {
    CONSULTATION: 550001,
    RESTORE_SOFT: 550002,
    RESTORE_HARD: 550003,
    RESTORE_LVS: 550005,
    WITHDRAW: 550006,
};
/*
export const PERFORM_RESULTS_TYPES = [
    {
        value: PERFORM_RESULT_TYPE.CONSULTATION,
        label: "Консультация пользователей ПО ПТС ГАС \"Правосудие\"",
    },
    {
        value: PERFORM_RESULT_TYPE.RESTORE_SOFT,
        label: "Восстановление работы ПО ПТС ГАС \"Правосудие\"",
    },
    {
        value: PERFORM_RESULT_TYPE.RESTORE_HARD,
        label: "Восстановление работы АППАРАТНОЙ части ПТС ГАС \"Правосудие\"",
    },
    {
        value: PERFORM_RESULT_TYPE.RESTORE_LVS,
        label: "Восстановление работы на ЛВС, монтаж дополнительных рабочих мест",
    },
    {
        value: PERFORM_RESULT_TYPE.WITHDRAW,
        label: "Организация вывода из эксплуатации и списания оборудования",
    },
];
*/
// requsts categories
export const REQUEST_CATEGORY = {
    SERVER: 520001,
    PERSONAL: 520009,
    INVORM_KIOSK: 520021,
    AUDIO_VIDEO: 520022,
    ACTIVE_NET: 520002,
    ANTIVIRUS_PO: 520013,
    ANTIVIRUS_RESTORE: 520008,
    KOLCHUGA: 520018,
    JUPITER_MAIN: 520014,
    JUPITER_LOCK: 520015,
    JUPITER_CLIENT: 520016,
    SAFETY_OTHER: 520017,
    VKS_CODEC: 520019,
    VKS_BROKE: 520020,
    LVS_CLIENT: 520012,
    OTHER: 520010,
};

export const REQUESTS_CATEGORIES = [
    {
        value: REQUEST_CATEGORY.SERVER,
        label: "Серверное оборудование, серверные составляющие ПО",
    },
    {
        value: REQUEST_CATEGORY.PERSONAL,
        label: "Рабочие станции (клиентское оборудование), клиентские части ПО",
    },
    {
        value: REQUEST_CATEGORY.INVORM_KIOSK,
        label: "Информационные киоски",
    },
    {
        value: REQUEST_CATEGORY.AUDIO_VIDEO,
        label: "Оборудование систем аудио (видео) фиксации судебных заседаний",
    },
    {
        value: REQUEST_CATEGORY.ACTIVE_NET,
        label: "Активное сетевое оборудование",
    },
    {
        value: REQUEST_CATEGORY.ANTIVIRUS_PO,
        label: "Антивирусное ПО",
    },
    {
        value: REQUEST_CATEGORY.ANTIVIRUS_RESTORE,
        label: "Ликвидация последствий вирусных атак",
    },
    {
        value: REQUEST_CATEGORY.KOLCHUGA,
        label: "Нарушения в работе ИВК \"Кольчуга\"",
    },
    {
        value: REQUEST_CATEGORY.JUPITER_MAIN,
        label: "Нарушения в работоспособности \"Главной машины\" ИВК \"Юпитер\"",
    },
    {
        value: REQUEST_CATEGORY.JUPITER_LOCK,
        label: "Нарушения в  работе шлюзов ИВК \"Юпитер\"",
    },
    {
        value: REQUEST_CATEGORY.JUPITER_CLIENT,
        label: "Настройки клиентских частей ИВК \"Юпитер\"",
    },
    {
        value: REQUEST_CATEGORY.SAFETY_OTHER,
        label: "Прочие категории, связанные с обеспечением безопасности информации",
    },
    {
        value: REQUEST_CATEGORY.VKS_CODEC,
        label: "Настройки ПО кодеков ВКС",
    },
    {
        value: REQUEST_CATEGORY.VKS_BROKE,
        label: "Нарушения в работе кодеков ВКС",
    },
    {
        value: REQUEST_CATEGORY.LVS_CLIENT,
        label: "Проблемы подключения клиентских мест к ЛВС",
    },
    {
        value: REQUEST_CATEGORY.OTHER,
        label: "Прочие категории заявок",
    },
];

// problems categories
export const PROBLEM_CATEGORY = {
    HARD: 540001,
    SOFT_COMMON_WORK: 540002,
    SOFT_COMMON_SETTING: 540003,
    SOFT_SPECIAL_WORK: 540004,
    SOFT_SPECIAL_SETTING: 540005,
    CONSULTATION: 540006,
};

export const PROBLEMS_CATEGORIES = [
    {
        value: PROBLEM_CATEGORY.HARD,
        label: "Неисправность аппаратной части (оборудования)",
    },
    {
        value: PROBLEM_CATEGORY.SOFT_COMMON_WORK,
        label: "Некорректная работа общего ПО",
    },
    {
        value: PROBLEM_CATEGORY.SOFT_COMMON_SETTING,
        label: "Некорректные настройки общего ПО",
    },
    {
        value: PROBLEM_CATEGORY.SOFT_SPECIAL_WORK,
        label: "Некорректная работа специального ПО",
    },
    {
        value: PROBLEM_CATEGORY.SOFT_SPECIAL_SETTING,
        label: "Некорректные настройки специального ПО",
    },
    {
        value: PROBLEM_CATEGORY.CONSULTATION,
        label: "Необходимость проведения консультации пользователей ОА",
    },
];

// tool guarantee types
export const GUARANTY_TYPES_VALUES = {
    GUARANTY: 1,
    NOT_GUARANTY: 2,
    // GUARANTY_BUT_NOT: 3,
};

export const GUARANTY_TYPES = [
    { value: GUARANTY_TYPES_VALUES.GUARANTY, label: "Гарантийное оборудование" },
    { value: GUARANTY_TYPES_VALUES.NOT_GUARANTY, label: "Не гарантийное оборудование" },
    // { value: GUARANTY_TYPES_VALUES.GUARANTY_BUT_NOT, label: "Гарантийное оборудование не гарантийный случай" },
];

// services
/*
export const services = [
    { value: 1, label: "ООО 'Элекс.Ком'" },
    { value: 2, label: "ЗАО 'ИНФОРСЕР'" },
    { value: 3, label: "ООО 'Актион'" },
    {
        value: 4,
        label: "Филиал ФГБУ ИАЦ Судебного департамента в Томской области",
    },
];
*/

// learning types
export const LEARNING_TYPES = [
    { value: 1, label: "Инструктаж по работе с ОПО" },
    { value: 2, label: "Инструктаж по работе с СПО" },
    { value: 3, label: "Инструктаж по работе с АРМ" },
    { value: 4, label: "Инструктаж по работе с оргтехникой" },
    { value: 5, label: "Инструктаж по работе с файлами" },
    { value: 6, label: "Инструктаж по работе с системой ВКС" },
    { value: 7, label: "Инструктаж по работе с системой аудио-протоколирования" },
    { value: 8, label: "Инструктаж по работе с системой 'Защиты свидетелей'" },
    { value: 9, label: "Инструктаж по работе с системой ЛВС" },
    { value: 10, label: "Инструктаж по включению/отключению сетевого и серверного оборудования" },
    { value: 11, label: "Инструктаж по работе ЭЦП" },
];

// tools states
export const TOOLS_STATES = [
    { id: TOOLS_STATES_VALUES.WAITING, title: "Ожидание постановки на баланс" },
    { id: TOOLS_STATES_VALUES.IN_WORK, title: "Рабочее" },
    { id: TOOLS_STATES_VALUES.BROKEN, title: "Неисправно" },
    { id: TOOLS_STATES_VALUES.WAITING_UTILIZE, title: "Ожидание списания" },
    { id: TOOLS_STATES_VALUES.UTILIZED, title: "Списано" },
];

// tools owners types
export const TOOLS_OWNERS_TYPES_VALUES = {
    BASE: 1,
    HOST: 2,
};

export const TOOLS_OWNERS_TYPES = [
    { value: TOOLS_OWNERS_TYPES_VALUES.BASE, label: "Основное средство ФГБУ ИАЦ Судебного департамента" },
    { value: TOOLS_OWNERS_TYPES_VALUES.HOST, label: "Программно-техническое средство Балансодержателя" },
];

export const REQUEST_INNER_KINDS_VALUES = {
    REF_MODIFY_ORGS: 1,
    REF_MODIFY_TOOLS: 2,
    ERROR_IN_PROGRAM: 3,
    PROPOSAL_CHANGE: 4,
};

export const REQUEST_INNER_KINDS = [
    { value: REQUEST_INNER_KINDS_VALUES.REF_MODIFY_ORGS, label: "Запрос на изменение в справочнике Организации" },
    { value: REQUEST_INNER_KINDS_VALUES.REF_MODIFY_TOOLS, label: "Запрос на добавление оборудования" },
    { value: REQUEST_INNER_KINDS_VALUES.ERROR_IN_PROGRAM, label: "Ошибки в Программе" },
    { value: REQUEST_INNER_KINDS_VALUES.PROPOSAL_CHANGE, label: "Предложение по модификации" },
];

export const TOOLS_EVENTS_VALUES = {
    START_WORK: 1,
    REPAIR: 2,
    BROKEN: 3,
    DISCARD: 4,
};

export const TOOLS_EVENTS = [
    { value: TOOLS_EVENTS_VALUES.START_WORK, label: "Ввод в эксплуатацию" },
    { value: TOOLS_EVENTS_VALUES.REPAIR, label: "Ремонт" },
    { value: TOOLS_EVENTS_VALUES.BROKEN, label: "Заключение о неремонтопригодности" },
    { value: TOOLS_EVENTS_VALUES.DISCARD, label: "Списано" },
];
