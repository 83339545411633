<template>
    <div class="request-common-grid">
        <q-btn
            filled
            color="primary"
            label="Принять к исполнению"
            :disable="!canBeEdited ||
                !(
                    request.request_status_id == REQUEST_STATUS_VALUES.WAITING_ENGINEER ||
                    request.request_status_id == REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED
                )"
            class="cell-span-col-2"
            @click="takeForPerform"
        />
        <q-input
            v-model="requestTextExpl"
            outlined
            dense
            class="cell-span-col-6"
            :inputStyle="{ resize: 'none' }"
            label="Описание изученной заявки"
            type="textarea"
            :readonly="!canBeEdited"
            :disable="request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
        />
        <!-- select request type -->
        <q-select
            v-model="reqKindId"
            outlined
            dense
            :options="REQUEST_KINDS"
            optionValue="id"
            optionLabel="title"
            mapOptions
            emitValue
            label="Тип заявки"
            :class="[
                REQUEST_KINDS_VALUES.SOFT_SPECIAL,
                REQUEST_KINDS_VALUES.SOFT_COMMON,
                REQUEST_KINDS_VALUES.REPAIR_HARD,
                REQUEST_KINDS_VALUES.RESTORE,
                REQUEST_KINDS_VALUES.TASK,
                REQUEST_KINDS_VALUES.REVOKE,
            ].includes(reqKindId)
                ? 'cell-span-col-3'
                : 'cell-span-col-6'"
            hideBottomSpace
            errorMessage="Укажите тип заявки"
            :error="validations.request_kind_id && !reqKindId"
            :readonly="!canBeEdited"
            :disable="request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
        />
        <!-- request tool -->
        <DialogEditRequestTool
            v-if="[
                REQUEST_KINDS_VALUES.SOFT_SPECIAL,
                REQUEST_KINDS_VALUES.SOFT_COMMON,
                REQUEST_KINDS_VALUES.REPAIR_HARD,
                REQUEST_KINDS_VALUES.RESTORE,
                REQUEST_KINDS_VALUES.TASK,
                REQUEST_KINDS_VALUES.REVOKE,
            ].includes(reqKindId)"
            :validations="validations"
            :disable="request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
            class="cell-span-col-3"
        />
        <!-- select soft -->
        <q-select
            v-if="[
                REQUEST_KINDS_VALUES.SOFT_SPECIAL,
                REQUEST_KINDS_VALUES.SOFT_COMMON,
            ].includes(reqKindId)"
            v-model="programId"
            outlined
            dense
            :options="programsFiltered"
            optionValue="id"
            mapOptions
            emitValue
            label="Программное обеспечение"
            class="cell-span-col-6"
            hideBottomSpace
            errorMessage="Укажите программное обеспечение"
            :error="validations.program_id && !programId"
            :readonly="!canBeEdited"
            :disable="request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
        >
            <template #selected-item="scope">
                {{ scope.opt.gas_sub_system ? `${scope.opt.gas_sub_system}, ` : "" }}
                {{ scope.opt.title }}
            </template>
            <template #option="scope">
                <q-item v-bind="scope.itemProps">
                    <q-item-section>
                        <q-item-label>
                            {{ scope.opt.gas_sub_system }}
                            {{ scope.opt.title }}
                        </q-item-label>
                        <q-item-label caption>
                            {{ scope.opt.description && scope.opt.description.length > 200
                                ? `${scope.opt.description.substring(0, 200)}...`
                                : scope.opt.description
                            }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-select>

        <q-select
            v-if="[
                REQUEST_KINDS_VALUES.SOFT_SPECIAL,
                REQUEST_KINDS_VALUES.TASK,
                REQUEST_KINDS_VALUES.REVOKE,
                REQUEST_KINDS_VALUES.PROPOSE,
            ].includes(reqKindId)"
            v-model="subsystem"
            :options="programsSubsystems"
            emitValue
            mapOptions
            optionValue="id"
            optionLabel="title"
            outlined
            dense
            label="Подсистемы ГАС 'Правосудие'"
            class="cell-span-col-6"
            :disable="request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
        />

        <DialogEditRequestMovingSoft
            v-if="[
                REQUEST_KINDS_VALUES.RESTORE,
                REQUEST_KINDS_VALUES.SOFT_SPECIAL,
                REQUEST_KINDS_VALUES.SOFT_COMMON,
            ].includes(reqKindId)"
            :canBeEdited="!!canBeEdited"
        />
        <DialogEditRequestMovingLearning
            v-if="reqKindId == REQUEST_KINDS_VALUES.LEARNING"
            :validations="validations"
            :canBeEdited="!!canBeEdited"
        />
        <DialogEditRequestMovingRepair
            v-if="reqKindId == REQUEST_KINDS_VALUES.REPAIR_HARD"
            :validations="validations"
            :canBeEdited="!!canBeEdited"
        />
        <DialogEditRequestMovingConsultation
            v-if="reqKindId == REQUEST_KINDS_VALUES.CONSULTATION"
            :canBeEdited="!!canBeEdited"
        />

        <DialogEditRequestMovingApprove
            v-if="allowDelRequest"
            :canBeEdited="!!canBeEdited"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
    REQUEST_STATUS_VALUES,
    REQUEST_KINDS_VALUES,
    REQUEST_KINDS,
    RIGHTS_CONSTANTS,
} from "@/store/constants";
import generateRequestProperty from "@/helpers/generateRequestProperty";

import DialogEditRequestMovingSoft from "./DialogEditRequestMovingSoft.vue";
import DialogEditRequestMovingLearning from "./DialogEditRequestMovingLearning.vue";
import DialogEditRequestMovingRepair from "./DialogEditRequestMovingRepair.vue";
import DialogEditRequestMovingConsultation from "./DialogEditRequestMovingConsultation.vue";
import DialogEditRequestMovingApprove from "./DialogEditRequestMovingApprove.vue";
import DialogEditRequestTool from "./DialogEditRequestTool.vue";

export default {
    name: "DialogEditRequestMoving",
    components: {
        DialogEditRequestMovingSoft,
        DialogEditRequestMovingLearning,
        DialogEditRequestMovingRepair,
        DialogEditRequestMovingConsultation,
        DialogEditRequestMovingApprove,
        DialogEditRequestTool,
    },
    props: {
        validations: {
            type: Object,
            required: true,
        },
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            REQUEST_STATUS_VALUES,
            REQUEST_KINDS_VALUES,
            REQUEST_KINDS,
        };
    },
    computed: {
        ...mapGetters({
            getOrgProperty: "getOrgProperty",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            request: "request",
            currentUser: "currentUser",
            programs: "programs",
            programsSubsystems: "programsSubsystems",
            problemsCategories: "problemsCategories",
        }),
        reqKindId: {
            get() {
                return this.request.request_kind_id;
            },
            set(value) {
                this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_kind_id",
                        value,
                    },
                );

                this.programId = null;
            },
        },
        reqUnitId() {
            return this.request.unit_id;
        },
        orgKindId() {
            const orgId = this.getOrgIdOfUnit(this.reqUnitId);
            const kind_id = this.getOrgProperty(orgId, "kind_id");
            return kind_id;
        },
        programsFiltered() {
            return this.programs.filter((p) => (
                (this.reqKindId === REQUEST_KINDS_VALUES.SOFT_COMMON && p.type === 1) ||
                (this.reqKindId === REQUEST_KINDS_VALUES.SOFT_SPECIAL && p.type === 2)
            ) &&
                p.programs_org_types.map((t) => t.org_type_id).includes(this.orgKindId));
        },
        programId: {
            get() {
                return this.request.program_id;
            },
            set(value) {
                this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "program_id",
                        value,
                    },
                );

                const program = this.programs.find((p) => p.id === value);

                if (program && program.type === 2) {
                    this.subsystem = program.gas_sub_system_id;
                }
            },
        },
        requestStatusId: generateRequestProperty("request_status_id"),
        subsystem: generateRequestProperty("subsystem_id"),
        requestTextExpl: generateRequestProperty("request_text_expl"),
        allowDelRequest() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_DELETE & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        takeForPerform() {
            this.$store.dispatch("setRequestEngineers", [this.currentUser.id]);

            this.requestStatusId = REQUEST_STATUS_VALUES.PERFORMING;

            if (!this.requestTextExpl) {
                this.requestTextExpl = this.request.request_text;
            }
        },
    },
};
</script>
