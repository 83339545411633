<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card class="dialog-card">
            <div class="text-h6 dialog-title">
                Учетная запись
            </div>

            <q-tabs
                :modelValue="tabCurrent"
                dense
                activeColor="primary"
                indicatorColor="primary"
                align="justify"
                keepAlive
                @update:modelValue="tabChange"
            >
                <q-tab
                    name="info"
                    label="Общее"
                />
                <q-tab
                    v-if="!readOnly"
                    name="roles"
                    label="Роли"
                />
                <q-tab
                    v-if="!readOnly"
                    name="buildings"
                    label="Объекты"
                />
            </q-tabs>

            <q-separator />

            <q-tab-panels
                v-model="tabCurrent"
                animated
            >
                <q-tab-panel name="info">
                    <q-card-section class="scroll no-padding dialog-section">
                        <div class="dialog-common-grid-col-2">
                            <q-input
                                ref="refSurname"
                                v-model="userT.surname"
                                outlined
                                dense
                                label="Фамилия"
                                class="cell cell-span-col-2"
                                hideBottomSpace
                                :rules="[val => val && val.length >= 1 || 'Введите фамилию']"
                                :readonly="readOnly"
                            />
                            <q-input
                                ref="refName"
                                v-model="userT.name"
                                outlined
                                dense
                                label="Имя"
                                hideBottomSpace
                                :rules="[val => val && val.length >= 1 || 'Введите имя']"
                                :readonly="readOnly"
                            />
                            <q-input
                                v-model="userT.patronymic"
                                outlined
                                dense
                                label="Отчество"
                                :readonly="readOnly"
                            />
                            <q-input
                                v-model="userT.phone"
                                outlined
                                dense
                                label="Телефон"
                                :readonly="readOnly"
                            />
                            <q-input
                                ref="refEmail"
                                v-model="userT.email"
                                outlined
                                dense
                                label="Email"
                                :readonly="readOnly"
                                hideBottomSpace
                                :rules="[checkEmail]"
                            />
                            <SelectFilter
                                v-model="regionId"
                                :options="regions"
                                label="Регион"
                                class="cell cell-span-col-2"
                            />
                            <q-select
                                ref="refOrganization"
                                v-model="userT.organization_id"
                                outlined
                                dense
                                :options="organizationsFilter"
                                optionValue="id"
                                optionLabel="name"
                                mapOptions
                                emitValue
                                label="Организация"
                                class="cell cell-span-col-2"
                                hideBottomSpace
                                :rules="[val => val && val > 0 || 'Укажите организацию']"
                                :readonly="readOnly"
                            />
                            <q-select
                                v-model="userT.post_id"
                                outlined
                                dense
                                :options="posts"
                                optionValue="id"
                                optionLabel="title"
                                mapOptions
                                emitValue
                                label="Классификатор должностей"
                                class="cell cell-span-col-2"
                                :readonly="readOnly"
                            />
                            <q-input
                                v-model="userT.post_description"
                                outlined
                                dense
                                label="Должность"
                                class="cell cell-span-col-2"
                                :readonly="readOnly"
                            />
                            <div
                                v-if="isSigner"
                                class="message-warning cell-span-col-2"
                            >
                                Внимание! Строка "Документ-основание полномочий" заполняется строго по образцу:
                                действующий на основании доверенности №1 от 01.01.2024
                                (указываются реквизиты документа-основания полномочий лица).
                            </div>
                            <q-input
                                v-if="isSigner"
                                v-model="userT.due_order"
                                outlined
                                dense
                                label="Документ-основание полномочий"
                                class="cell cell-span-col-2"
                                autogrow
                                :readonly="readOnly"
                            />

                            <template v-if="allowEditUsers">
                                <q-input
                                    ref="refLogin"
                                    v-model="userT.login"
                                    outlined
                                    dense
                                    label="Login"
                                    hideBottomSpace
                                    :rules="[checkLogin]"
                                    :readonly="readOnly"
                                />
                                <q-input
                                    v-model="userT.password"
                                    outlined
                                    dense
                                    label="Password"
                                    clearable
                                    :readonly="readOnly"
                                >
                                    <template #after>
                                        <q-btn
                                            round
                                            size="sm"
                                            :icon="matAutorenew"
                                            class="q-mr-sm"
                                            @click="generatePassword"
                                        />
                                    </template>
                                </q-input>
                            </template>

                            <q-checkbox
                                v-if="allowChangeStatus"
                                v-model="userInactive"
                                leftLabel
                                label="Учетная запись неактивна"
                                class="cell cell-span-col-2 cell-center"
                                :disable="readOnly"
                            />
                            <div class="cell cell-span-col-2">
                                Дата последнего посещения: {{ userLastVisit }}
                            </div>
                        </div>
                    </q-card-section>
                </q-tab-panel>
                <q-tab-panel name="roles">
                    <q-card-section class="scroll no-padding dialog-section">
                        <div class="simple-three-grid">
                            <!-- header -->
                            <div class="cell cell-header cell-center">
                                №
                            </div>
                            <div class="cell cell-header cell-center">
                                Наименование роли
                            </div>
                            <div class="cell cell-header cell-center">
                                <q-btn
                                    round
                                    :icon="matAdd"
                                    size="sm"
                                    color="primary"
                                >
                                    <q-tooltip
                                        :delay="300"
                                        anchor="top right"
                                        self="bottom middle"
                                    >
                                        Добавить роль
                                    </q-tooltip>

                                    <q-menu
                                        autoClose
                                        anchor="top left"
                                        self="bottom right"
                                    >
                                        <q-list style="min-width: 100px">
                                            <template
                                                v-for="role in rolesFilter"
                                                :key="role.id"
                                            >
                                                <q-item
                                                    clickable
                                                    @click="addUserRole(role.id)"
                                                >
                                                    <q-item-section>
                                                        {{ roleName(role.id) }}
                                                    </q-item-section>
                                                </q-item>
                                            </template>
                                        </q-list>
                                    </q-menu>
                                </q-btn>
                            </div>
                            <!-- data -->
                            <div
                                v-for="(role, roleIndex) in userT.users_roles"
                                :key="roleIndex"
                                class="row-wrapper"
                            >
                                <div class="cell cell-center">
                                    {{ roleIndex + 1 }}
                                </div>
                                <div class="cell">
                                    {{ roleName(role.role_id) }}
                                </div>
                                <div class="cell cell-center">
                                    <q-btn
                                        round
                                        :icon="matDelete"
                                        size="sm"
                                        @click="delUserRole(role.role_id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </q-card-section>
                </q-tab-panel>
                <q-tab-panel name="buildings">
                    <q-card-section class="scroll no-padding dialog-section">
                        <div class="simple-three-grid">
                            <!-- header -->
                            <div class="cell cell-header cell-center">
                                №
                            </div>
                            <div class="cell cell-header cell-center">
                                Объекты
                            </div>
                            <div class="cell cell-header cell-center">
                                <q-btn
                                    round
                                    :icon="matAdd"
                                    size="sm"
                                    color="primary"
                                >
                                    <q-tooltip
                                        :delay="300"
                                        anchor="top right"
                                        self="bottom middle"
                                    >
                                        Добавить объект
                                    </q-tooltip>

                                    <q-menu
                                        autoClose
                                        anchor="top left"
                                        self="bottom right"
                                    >
                                        <q-virtual-scroll
                                            v-slot="{ item, index }"
                                            style="max-height: 50%; min-width: 500px; max-width: 500px;"
                                            :items="unitsFullFilter"
                                            separator
                                        >
                                            <q-item
                                                :key="index"
                                                dense
                                                clickable
                                                @click="addUserUnit(item.id)"
                                            >
                                                <q-item-section>
                                                    <q-item-label>
                                                        {{ item.name }}
                                                    </q-item-label>
                                                    <q-item-label caption>
                                                        {{ item.address }}
                                                    </q-item-label>
                                                </q-item-section>
                                            </q-item>
                                        </q-virtual-scroll>
                                    </q-menu>
                                </q-btn>
                            </div>
                            <!-- data -->
                            <div
                                v-for="(unit, unitIndex) in userUnitsFull"
                                :key="unitIndex"
                                class="row-wrapper"
                            >
                                <div class="cell cell-center">
                                    {{ unitIndex + 1 }}
                                </div>
                                <div class="cell">
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ unit.name }}
                                            </q-item-label>
                                            <q-item-label caption>
                                                {{ unit.address }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </div>
                                <div class="cell cell-center">
                                    <q-btn
                                        round
                                        :icon="matDelete"
                                        size="sm"
                                        class="poeso-table--control-button"
                                        @click="removeUserUnit(unit.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </q-card-section>
                </q-tab-panel>
            </q-tab-panels>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-if="!readOnly"
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { date } from "quasar";
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

export default {
    name: "DialogEditUser",
    components: {
        SelectFilter,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            regionId: null,
            userT: {},
            tabCurrent: "info",
            userInactive: false,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            organizations: "orgs",
            posts: "posts",
            roles: "roles",
            roleName: "roleName",
            unitsFull: "unitsFull",
            userUnits: "userUnits",
            rolesWithRightIds: "rolesWithRightIds",
            regions: "regions",
        }),
        organizationsFilter() {
            return this.regionId
                ? this.organizations.filter((o) => o.region_id === this.regionId)
                : this.organizations;
        },
        unitsFullFilter() {
            return this.regionId
                ? this.unitsFull.filter((u) => u.region_id === this.regionId)
                : this.unitsFull;
        },
        rolesFilter() {
            return this.roles
                .filter((r) => !r.role_is_hotline)
                .filter((r) => r.access_level <= this.currentUser.accessLevel)
                .filter((r) => !this.userT.users_roles.map((ur) => ur.role_id).includes(r.id));
        },
        isSigner() {
            // TODO: do something with strings
            const rolesSigners = this.rolesWithRightIds("RIGHT_SIGNING_DOCS_IAC")
                .concat(this.rolesWithRightIds("RIGHT_SIGNING_DOCS_UNIT"))
                .concat(this.rolesWithRightIds("RIGHT_SIGNING_DOCS_FINAL"));

            return this.userT.users_roles
                .reduce(
                    (acc, ur) => acc || rolesSigners.includes(ur.role_id),
                    false,
                );
        },
        userUnitsFull() {
            const userUnitsIds = this.userUnits(this.userT.id).map((uu) => uu.unit_id);
            return this.unitsFullFilter.filter((u) => userUnitsIds.includes(u.id));
        },
        readOnly() {
            return this.userT.is_inactive === true;
        },
        userLastVisit() {
            return this.user.last_visit
                ? date.formatDate(
                    date.extractDate(this.user.last_visit, "YYYY-MM-DD HH:mm:ss"),
                    "DD.MM.YYYY HH:mm:ss",
                )
                : "нет данных";
        },
        allowEditUsers() {
            return this.currentUser.rightsObject.RIGHT_USERS_LIST === RIGHTS_CONSTANTS.FULL;
        },
        allowChangeStatus() {
            return this.currentUser.rightsObject.RIGHT_USER_STATUS_EDIT === RIGHTS_CONSTANTS.FULL;
        },
    },
    watch: {
        regionId(v) {
            if (v) {
                const org = this.organizations.find((o) => o.id === this.userT.organization_id);
                if (!org || org.region_id !== v) {
                    this.userT.organization_id = null;
                }

                const unitsRegIds = this.unitsFull.filter((uu) => uu.region_id === v).map((uu) => uu.id);
                const userUnitsIds = this.userUnits(this.userT.id).map((uu) => uu.unit_id);

                for (let i = 0; i < userUnitsIds.length; i++) {
                    if (!unitsRegIds.includes(userUnitsIds[i])) {
                        this.$store.dispatch(
                            "removeUserUnit",
                            {
                                userId: this.userT.id,
                                unitId: userUnitsIds[i],
                            },
                        );
                    }
                }
            }
        },
    },
    methods: {
        checkErrorsInfo() {
            const hasErrors = Object.keys(this.$refs).reduce(
                (acc, key) => {
                    if (Object.prototype.hasOwnProperty.call(this.$refs[key], "validate")) {
                        this.$refs[key].validate();
                        return acc || this.$refs[key].hasError;
                    }
                    return acc;
                }, false,
            );

            return hasErrors;
        },
        checkLogin(login) {
            if (login.length < 3) {
                return "Введите login (минимум 3 символа)";
            }

            return /^[\w_]+$/.test(login) || "Только латинские буквы и цифры";
        },
        checkEmail(email) {
            return /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(email) || "Укажите email";
        },
        generatePassword() {
            const letters = [
                "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
                "abcdefghijklmnopqrstuvwxyz",
                "1234567890",
            ].join("");

            let retVal = "";

            for (let i = 0; i < 8; i++) {
                retVal = `${retVal}${letters.at(Math.floor(Math.random() * letters.length))}`;
            }

            this.userT.password = retVal;
        },
        addUserRole(roleId) {
            if (!this.userT.users_roles.map((ur) => ur.role_id).includes(roleId)) {
                this.userT.users_roles.push({ role_id: roleId });
            }
        },
        delUserRole(roleId) {
            this.userT.users_roles = this.userT.users_roles.filter((ur) => ur.role_id !== roleId);
        },
        addUserUnit(unitId) {
            this.$store.dispatch(
                "addUserUnit",
                {
                    userId: this.userT.id,
                    unitId,
                },
            );
        },
        removeUserUnit(unitId) {
            this.$store.dispatch(
                "removeUserUnit",
                {
                    userId: this.userT.id,
                    unitId,
                },
            );
        },
        tabChange(value) {
            if (this.$refs.refSurname && this.checkErrorsInfo()) {
                this.tabCurrent = "info";
            }
            else {
                this.tabCurrent = value;
            }
        },
        show() {
            this.userT = {
                ...window.structuredClone(this.user),
                password: "",
            };

            const org = this.organizations.find((o) => o.id === this.userT.organization_id);
            if (org) {
                this.regionId = org.region_id;
            }
            this.tabCurrent = "info";
            this.$refs.dialog.show();
            this.userInactive = this.user.is_inactive === true;
        },
        showConfirmDialog() {
            return new Promise((resolve) => {
                this.$q.dialog({
                    component: DialogConfirm,
                    componentProps: {
                        labelOk: "Продолжить",
                        dialogMessage: "Учетная запись работника станет неактивной без возможности восстановления, продолжить?",
                    },
                })
                    .onOk(() => {
                        resolve(true);
                    })
                    .onCancel(() => {
                        resolve(false);
                    });
            });
        },
        async onOkClick() {
            if (this.tabCurrent === "info" && this.checkErrorsInfo()) return;

            if (this.userInactive) {
                const res = await this.showConfirmDialog();

                if (!res) {
                    return;
                }

                this.userT.is_inactive = true;
                this.userT.users_roles = [];
                await this.$store.dispatch("removeUserUnits", this.userT.id);
            }

            this.$store.dispatch("saveUser", this.userT)
                .then((message) => {
                    this.$q.notify({
                        type: "notify-success",
                        message,
                    });
                    this.$refs.dialog.hide();
                })
                .catch((message) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message,
                    });
                });
        },
        onCancelClick() {
            // TODO: this seems to be overhead, consider another logic
            this.$store.dispatch("getUsersRolesFromDB");
            this.$store.dispatch("getUsersUnitsFromDB");
            this.$refs.dialog.hide();
        },
    },
};
</script>
