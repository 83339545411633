<template>
    <q-select
        v-model="repairKindId"
        outlined
        dense
        :options="GUARANTY_TYPES"
        mapOptions
        emitValue
        label="Тип гарантийности оборудования"
        class="cell-span-col-6"
        readonly
    />
    <q-select
        v-model="viewBefore"
        outlined
        dense
        label="Результат осмотра до ремонта"
        clearable
        :options="OPTIONS_VEIW_BEFORE"
        emitValue
        mapOptions
        class="cell-span-col-6"
        :disable="mayBeEdited"
    />
    <q-input
        v-model="noteBefore"
        outlined
        dense
        class="cell-span-col-6"
        type="textarea"
        :inputStyle="{ resize: 'none' }"
        label="Комментарии после осмотра до ремонта"
        :disable="mayBeEdited"
    />
    <q-input
        v-model="troublesDisplay"
        outlined
        dense
        class="cell-span-col-6"
        :disable="mayBeEdited"
        label="Описание проявления неисправности"
        autogrow
    />
    <q-input
        v-model="troublesList"
        outlined
        dense
        class="cell-span-col-6"
        autogrow
        label="Установлены следующие неисправности ПТС"
        :disable="mayBeEdited"
    />

    <q-btn
        filled
        class="cell-span-col-2"
        color="primary"
        label="Сформировать Акт СО-7"
        :disable="denyActCo7 || !canBeEdited"
        @click="createActCo7"
    />
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="№ акта СО-7"
        :stackLabel="!!co7Number"
    >
        <div
            class="self-center full-width no-outline"
            tabindex="0"
        >
            {{ co7Number }}
        </div>
    </q-field>
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="Дата акта СО-7"
        :stackLabel="!!co7Date"
    >
        <div
            class="self-center full-width no-outline"
            tabindex="0"
        >
            {{ co7Date }}
        </div>
    </q-field>

    <PoeDatePicker
        v-model="dateToolIn"
        labelTitle="Дата поступления оборудования в филиал"
        emptyDateAllowed
        classOuter="cell-span-col-3"
        :disable="!canBeEdited"
        :futureDateAllowed="false"
        errorMessage="Не может быть меньше даты заявки"
        :error="validations.tool_in_dt"
        hideBottomSpace
    />
    <q-btn
        filled
        class="cell-span-col-3"
        color="primary"
        label="Сформировать Акт СО-4.1"
        style="width: 100%;"
        :disable="denyActCo7 || !canBeEdited"
        @click="actCo41"
    />

    <q-select
        v-model="repairTypeId"
        :options="REPAIRS_TYPES"
        emitValue
        mapOptions
        outlined
        dense
        class="cell-span-col-6"
        label="Вид ремонта"
        :disable="!canBeEdited || toolToDismiss"
        clearable
        errorMessage="Неверно указан вид ремонта! Тип гарантийности оборудования не соответствует виду гарантийности ремонта."
        :error="guarantyIsNotRight"
        hideBottomSpace
    />
    <q-select
        v-model="repairResult"
        :options="REPAIRS_RESULTS"
        emitValue
        mapOptions
        outlined
        dense
        class="cell-span-col-4"
        label="Результаты ремонта"
        style="flex-grow: 1;"
        :disable="!canBeEdited || toolToDismiss"
        clearable
    />
    <PoeDatePicker
        v-model="resultDate"
        labelTitle="Дата ремонта"
        emptyDateAllowed
        classOuter="cell-span-col-2"
        :disable="!canBeEdited || toolToDismiss"
        :futureDateAllowed="false"
        errorMessage="Не может быть меньше даты поступления"
        :error="validations.result_date"
        hideBottomSpace
    />
    <!-- spares -->
    <MovingRepairSpares
        v-if="repairTypeId == REPAIRS_TYPES_VALUES.FILIAL_SPARE"
        class="cell-span-col-6"
        :canBeEdited="canBeEdited"
        :allowViewCost="allowViewCost"
    />
    <!-- works -->
    <div class="cell-span-col-6">
        <div class="text-h6">
            Выполненные работы
        </div>
        <div :class="[
            'q-pb-xs',
            allowViewCost ? 'request-work-cost-grid' : 'request-work-cost-grid--short',
        ]">
            <!-- headr -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Выполненная работа
            </div>
            <div
                v-if="allowViewCost"
                class="cell cell-header cell-center"
            >
                Стоимость работ
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="sm"
                    color="primary"
                    :disable="!canBeEdited || toolToDismiss"
                    @click="addWork"
                />
            </div>
            <!-- data -->
            <RequestWorkRow
                v-for="(work, index) in reqWorks"
                :key="index"
                :work="work"
                :index="index"
                :canBeEdited="canBeEdited"
                :allowViewCost="allowViewCost"
            />
        </div>
    </div>

    <q-input
        v-if="allowViewCost"
        :modelValue="reqWorksCost"
        readonly
        outlined
        dense
        class="cell-span-col-6"
        label="Общая стоимость выполненных работ"
        :disable="!canBeEdited"
    />
    <!-- service center -->
    <q-select
        v-model="serviceRepairId"
        outlined
        dense
        class="cell-span-col-2"
        label="Сервисный центр"
        clearable
        :options="servicesRepairs"
        optionValue="id"
        optionLabel="title"
        emitValue
        mapOptions
        errorMessage="Нужно выбрать"
        :disable="mayBeEdited || toolToDismiss"
        :error="validations.service_repair_id && !serviceRepairId"
        hideBottomSpace
    />
    <PoeDatePicker
        v-model="datePass"
        labelTitle="Дата передачи в СЦ"
        emptyDateAllowed
        classOuter="cell-span-col-2"
        :disable="mayBeEdited || toolToDismiss"
        :futureDateAllowed="false"
        errorMessage="Не может быть меньше даты поступления"
        :error="validations.date_pass"
        hideBottomSpace
    />
    <PoeDatePicker
        v-model="dateReturn"
        labelTitle="Дата возврата из СЦ"
        emptyDateAllowed
        classOuter="cell-span-col-2"
        :disable="mayBeEdited || toolToDismiss"
        :futureDateAllowed="false"
        errorMessage="Не может быть меньше даты передачи в СЦ"
        :error="validations.date_return"
        hideBottomSpace
    />
    <PoeDatePicker
        v-model="dateToolOut"
        labelTitle="Дата выбытия оборудования из филиала"
        emptyDateAllowed
        classOuter="cell-span-col-3"
        :disable="!canBeEdited"
        :futureDateAllowed="false"
        errorMessage="Не может быть меньше даты поступления"
        :error="validations.tool_out_dt"
        hideBottomSpace
    />
    <q-btn
        filled
        color="primary"
        label="Сформировать Акт СО-4.2"
        class="cell-span-col-3"
        :disable="denyActCo7 || !canBeEdited"
        @click="actCo42"
    />

    <q-select
        v-model="viewAfter"
        outlined
        dense
        label="Результат осмотра после ремонта"
        clearable
        :options="OPTIONS_VIEW_AFTER"
        emitValue
        mapOptions
        class="cell-span-col-6"
        :disable="mayBeEdited || toolToDismiss"
    />
    <q-input
        v-model="noteAfter"
        outlined
        dense
        class="cell-span-col-6"
        type="textarea"
        :inputStyle="{ resize: 'none' }"
        label="Комментарии после осмотра после ремонта"
        :disable="mayBeEdited || toolToDismiss"
    />
    <q-btn
        filled
        color="primary"
        class="cell-span-col-2"
        label="Сформировать Акт СО-8"
        :disable="denyActCo8 || !canBeEdited || toolToDismiss"
        @click="createActCo8"
    />
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="№ акта СО-8"
        :stackLabel="!!co8Number"
    >
        <div
            class="self-center full-width no-outline"
            tabindex="0"
        >
            {{ co8Number }}
        </div>
    </q-field>
    <q-field
        outlined
        dense
        label="Дата акта СО-8"
        class="cell-span-col-2"
        :stackLabel="!!co8Date"
    >
        <div
            class="self-center full-width no-outline"
            tabindex="0"
        >
            {{ co8Date }}
        </div>
    </q-field>
</template>

<script>
import { mapGetters } from "vuex";
import { createDoc } from "@/helpers/createDoc";
import { date } from "quasar";
import {
    REQUEST_STATUS_VALUES,
    GUARANTY_TYPES_VALUES,
    REPAIRS_TYPES_VALUES,
    OPTIONS_VIEW_BEFORE_VALUES,
    OPTIONS_VEIW_BEFORE,
    OPTIONS_VIEW_AFTER,
    REPAIRS_RESULTS,
    REPAIRS_TYPES,
    GUARANTY_TYPES,
    DATE_LOCALE_2,
    RIGHTS_CONSTANTS,
} from "@/store/constants";
import generateRequestProperty from "@/helpers/generateRequestProperty";
import { guarantyRemains } from "@/helpers/helpers";

import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import RequestWorkRow from "./RequestWorkRow.vue";
import DialogCreateActCO7 from "../DialogSigners/DialogCreateActCO7.vue";
import MovingRepairSpares from "./MovingRepairSpares.vue";

export default {
    name: "DialogEditRequestMovingRepair",    // eslint-disable-line
    components: {
        PoeDatePicker,
        RequestWorkRow,
        MovingRepairSpares,
    },
    props: {
        validations: {
            type: Object,
            required: true,
        },
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            REPAIRS_TYPES_VALUES,
            OPTIONS_VEIW_BEFORE,
            OPTIONS_VIEW_AFTER,
            REPAIRS_RESULTS,
            REPAIRS_TYPES,
            GUARANTY_TYPES,
        };
    },
    data() {
        return {
            tool: null,
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
            currentUser: "currentUser",
            requestDocs: "requestDocs",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getUserFIO: "getUserFIO",
            getUserFIOshort: "getUserFIOshort",
            getUserPostTitle: "getUserPostTitle",
            getUserDueOrder: "getUserDueOrder",
            getManufacturerName: "getManufacturerName",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            unitAddress: "unitAddress",
            reqWorks: "reqWorks",
            reqWorksCost: "reqWorksCost",
            filialOrg: "filialOrg",
            servicesRepairs: "servicesRepairs",
            getSignersCountByOrgKind: "getSignersCountByOrgKind",
            getKsaTitle: "getKsaTitle",
        }),
        vncode() {
            return this.getOrgProperty(
                this.getOrgIdOfUnit(this.request.unit_id),
                "vncode",
            );
        },
        signersCountByOrgKind() {
            return this.getSignersCountByOrgKind(this.request.unit_id);
        },
        requestYear() {
            return new Date(this.request.request_date_add).getFullYear();
        },
        repairKindId: generateRequestProperty("repair_kind_id"),
        repairTypeId: generateRequestProperty("repair_type_id"),
        repairResult: generateRequestProperty("repair_result_id"),
        datePass: generateRequestProperty("date_pass"),
        dateReturn: generateRequestProperty("date_return"),
        viewBefore: {
            get() {
                return this.request.view_res_before;
            },
            set(value) {
                const orgId = this.getOrgIdOfUnit(this.request.unit_id);
                const regionId = this.getOrgProperty(orgId, "region_id");

                const serviceRepair = this.servicesRepairs.find((sr) => (
                    sr.service_type === 1 && sr.region_id === regionId
                ));

                this.serviceRepairId = serviceRepair ? serviceRepair.id : null;

                this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "view_res_before",
                        value,
                    },
                );

                if (value === OPTIONS_VIEW_BEFORE_VALUES.TO_DISMISS) {
                    this.repairTypeId = null;
                    this.repairResult = null;
                }
            },
        },
        viewAfter: generateRequestProperty("view_res_after"),
        noteBefore: generateRequestProperty("comment_before"),
        troublesDisplay: generateRequestProperty("troubles_display"),
        troublesList: generateRequestProperty("troubles_list"),
        noteAfter: generateRequestProperty("comment_after"),
        dateToolIn: generateRequestProperty("tool_in_dt"),
        dateToolOut: generateRequestProperty("tool_out_dt"),
        serviceRepairId: generateRequestProperty("service_repair_id"),
        serviceCenterTitleAddress() {
            const sr = this.servicesRepairs.find((s) => (
                s.region_id === this.currentUser.region_id &&
                s.service_type === 1
            ));

            return sr
                ? `${sr.title}, ${sr.address}, ${sr.telephone}`
                : "";
        },
        resultDate: {
            get() {
                const v = this.request.result_date;

                if (v && /\d\d\d\d\-\d\d\-\d\d 00:01:01/.test(v)) {
                    return date.formatDate(
                        date.extractDate(v, "YYYY-MM-DD"),
                        "DD.MM.YYYY",
                    );
                }
                return v || null;
            },
            set(v) {
                if (/\d\d\.\d\d\.\d\d\d\d/.test(v)) {
                    this.$store.dispatch(
                        "setRequestField",
                        {
                            field: "result_date",
                            value: v
                                ? date.formatDate(
                                    date.extractDate(v, "DD.MM.YYYY"),
                                    "YYYY-MM-DD 00:01:01",
                                )
                                : null,
                        },
                    );
                }
                else {
                    this.$store.dispatch(
                        "setRequestField",
                        {
                            field: "result_date",
                            value: v || null,
                        },
                    );
                }
            },
        },
        co7Number: generateRequestProperty("co_7_number"),
        co7Date: {
            get() {
                return this.request.co_7_date
                    ? date.formatDate(
                        date.extractDate(this.request.co_7_date, "YYYY-MM-DD"),
                        "DD.MM.YYYY",
                    )
                    : null;
            },
            set(v) {
                this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "co_7_date",
                        value: v,
                    },
                );
            },
        },
        denyActCo7() {
            return this.request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING ||
                !this.request.view_res_before;
        },
        co8Number: generateRequestProperty("co_8_number"),
        co8Date: {
            get() {
                return this.request.co_8_date
                    ? date.formatDate(
                        date.extractDate(this.request.co_8_date, "YYYY-MM-DD"),
                        "DD.MM.YYYY",
                    )
                    : null;
            },
            set(v) {
                this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "co_8_date",
                        value: v,
                    },
                );
            },
        },
        co41Number: generateRequestProperty("co_41_number"),
        co41Date: {
            get() {
                return this.request.co_41_date
                    ? date.formatDate(
                        date.extractDate(this.request.co_41_date, "YYYY-MM-DD"),
                        "DD.MM.YYYY",
                    )
                    : null;
            },
            set(v) {
                this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "co_41_date",
                        value: v,
                    },
                );
            },
        },
        denyActCo8() {
            return this.request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING ||
                !this.request.view_res_after ||
                !this.request.comment_after ||
                this.request.comment_after.length < 4;
        },
        toolDescription() {
            if (this.tool.serial_number === "") {
                return "";
            }

            const guaranty = guarantyRemains(this.tool.guaranty_dt, "остаток гарантийного срока ");

            return [
                `${this.getToolKindName(this.tool.tool_kind_id)}, `,
                `${this.getManufacturerName(this.tool.manufacturer_id)}, `,
                `${this.getToolModelName(this.tool.model_id)}, `,
                this.tool.work_in_dt
                    ? `год выпуска ${date.formatDate(
                        date.extractDate(this.tool.work_in_dt),
                        "YYYY",
                    )}, `
                    : "",
                ` инвентарный № ${this.tool.inventar_number}, `,
                ` серийный № ${this.tool.serial_number}, `,
                ` ${guaranty}`,
            ].join("");
        },
        toolGuarantyAndCO7() {
            if (this.tool.serial_number === "") {
                return "";
            }

            const guaranty_dt = date.extractDate(this.tool.guaranty_dt);
            const diff = date.getDateDiff(guaranty_dt, new Date(), "days");

            return [
                diff < 0 ? "Негарантийный" : "Гарантийный",
                this.co7Number,
                this.co7Date,
            ].join(", ");
        },
        toolGuarantySimple() {
            const guaranty_dt = date.extractDate(this.tool.guaranty_dt);
            const diff = date.getDateDiff(guaranty_dt, new Date(), "days");

            return diff < 0 ? "Негарантийный" : "Гарантийный";
        },
        toolName() {
            if (this.tool.serial_number === "") {
                return "";
            }

            return [
                this.getToolKindName(this.tool.tool_kind_id),
                this.getManufacturerName(this.tool.manufacturer_id),
                this.getToolModelName(this.tool.model_id),
            ].join(" ");
        },
        docsCount() {
            return this.requestDocs.length;
        },
        fileNameCO7() {
            return `${this.vncode}_actCO7_${this.request.number}_${this.requestYear}.docx`;
        },
        fileNameCO8() {
            return `${this.vncode}_actCO8_${this.request.number}_${this.requestYear}.docx`;
        },
        fileNameCO41() {
            return `${this.vncode}_actCO4.1_${this.request.number}_${this.requestYear}.docx`;
        },
        fileNameCO42() {
            return `${this.vncode}_actCO4.2_${this.request.number}_${this.requestYear}.docx`;
        },
        allowViewCost() {
            return !!(this.currentUser.rightsObject.RIGHT_VIEW_COST & RIGHTS_CONSTANTS.FULL);
        },
        guarantyIsNotRight() {
            if (!this.repairTypeId) {
                return false;
            }

            return (
                (
                    this.repairKindId === GUARANTY_TYPES_VALUES.GUARANTY &&
                    this.repairTypeId !== REPAIRS_TYPES_VALUES.GUARANTY
                ) ||
                (
                    this.repairKindId !== GUARANTY_TYPES_VALUES.GUARANTY &&
                    this.repairTypeId === REPAIRS_TYPES_VALUES.GUARANTY
                )
            );
        },
        mayBeEdited() {
            return (
                // status
                this.request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING ||
                // rights
                !this.canBeEdited
            );
        },
        toolToDismiss() {
            return this.viewBefore === OPTIONS_VIEW_BEFORE_VALUES.TO_DISMISS;
        },
    },
    watch: {
        docsCount() {
            if (!this.actIsPresentCO7()) {
                this.co7Number = null;
                this.co7Date = null;
            }
            if (!this.actIsPresentCO8()) {
                this.co8Number = null;
                this.co8Date = null;
            }
        },
        "request.tool_id": {
            handler() {
                this.getToolFromDb();
            },
        },
    },
    created() {
        this.getToolFromDb();
    },
    mounted() {
        if (!this.actIsPresentCO7()) {
            this.co7Number = null;
            this.co7Date = null;
        }
        if (!this.actIsPresentCO8()) {
            this.co8Number = null;
            this.co8Date = null;
        }
    },
    methods: {
        getToolFromDb() {
            return new Promise((resolve) => {
                this.$store.dispatch("getSingleToolFromDB", this.request.tool_id)
                    .then((tool) => {
                        this.tool = tool;
                        resolve();
                    })
                    .catch(() => {
                        this.tool = {
                            serial_number: "",
                            inventar_number: "",
                        };
                        resolve();
                    });
            });
        },
        actIsPresentCO7() {
            return this.requestDocs
                .map((rd) => rd.file_name)
                .includes(this.fileNameCO7);
        },
        actIsPresentCO8() {
            return this.requestDocs
                .map((rd) => rd.file_name)
                .includes(this.fileNameCO8);
        },
        actIsPresentCO41() {
            return this.requestDocs
                .map((rd) => rd.file_name)
                .includes(this.fileNameCO41);
        },
        actIsPresentCO42() {
            return this.requestDocs
                .map((rd) => rd.file_name)
                .includes(this.fileNameCO42);
        },
        addWork() {
            this.$store.dispatch("addRequestWork");
        },
        createActCo7() {
            if (this.actIsPresentCO7()) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Акт уже сформирован.<br>Перейдите в раздел 'Документы'",
                    html: true,
                });
            }
            else {
                this.$q.dialog({
                    component: DialogCreateActCO7,
                    componentProps: {
                        actType: "CO-7",
                        toolManufacturer: this.getManufacturerName(this.tool.manufacturer_id),
                        toolKind: this.getToolKindName(this.tool.tool_kind_id),
                        toolModel: this.getToolModelName(this.tool.model_id),
                        toolSerialNumber: this.tool.serial_number,
                        toolInventarNumber: this.tool.inventar_number,
                        troublesDisplay: this.troublesDisplay,
                        troublesList: this.troublesList,
                        viewBefore: this.viewBefore,
                        signersCountByOrgKind: this.signersCountByOrgKind,
                    },
                })
                    .onOk(({
                        actDate,
                        signers,
                        noteBefore,
                        signType,
                    }) => {
                        const requestDate = date.formatDate(
                            new Date(this.request.request_date_add),
                            "«DD» MMMM YYYY г.",
                            DATE_LOCALE_2,
                        );
                        const dateDocLong = date.formatDate(
                            date.extractDate(actDate, "DD.MM.YYYY"),
                            "«DD» MMMM YYYY г.",
                            DATE_LOCALE_2,
                        );

                        this.co7Number = `ТС-${this.vncode}-${this.request.number}/${this.requestYear}`;
                        this.co7Date = date.formatDate(
                            date.extractDate(actDate, "DD.MM.YYYY"),
                            "YYYY-MM-DD",
                        );

                        createDoc(
                            signers[2].id ? "act-co-7.docx" : "act-co-7-2.docx",
                            this.fileNameCO7,
                            {
                                act_number: this.co7Number,
                                act_date: dateDocLong,
                                act_org_ksa_title: this.getKsaTitle(this.request.unit_id),
                                act_org_title: this.getOrgProperty(
                                    this.getOrgIdOfUnit(this.request.unit_id),
                                    "name",
                                ),
                                act_org_address: this.unitAddress(this.request.unit_id),
                                act_org_vncode: this.vncode,
                                signer_iac_fio_full: this.getUserFIO(signers[0].id),
                                signer_iac_post: this.getUserPostTitle(signers[0].id),
                                signer_iac_dueorder: this.getUserDueOrder(signers[0].id) || signers[0].dueOrder,
                                signer_iac_fio_short: this.getUserFIOshort(signers[0].id),

                                signer_unit_post: this.getUserPostTitle(signers[1].id),
                                signer_unit_dueorder: this.getUserDueOrder(signers[1].id) || signers[1].dueOrder,
                                signer_unit_fio_short: this.getUserFIOshort(signers[1].id),

                                signer_final_fio_full: this.getUserFIO(signers[2].id) || this.getUserFIO(signers[1].id),
                                signer_final_post: this.getUserPostTitle(signers[2].id) || this.getUserPostTitle(signers[1].id),
                                signer_final_dueorder: this.getUserDueOrder(signers[2].id) ||
                                    signers[2].dueOrder ||
                                    this.getUserDueOrder(signers[1].id) ||
                                    signers[1].dueOrder,
                                signer_final_fio_short: this.getUserFIOshort(signers[2].id) || this.getUserFIOshort(signers[1].id),

                                request_date: requestDate,
                                request_number: this.request.number,
                                request_text: this.request.note,
                                request_note: noteBefore || "",
                                request_tool_full: this.toolDescription,
                                filial_title: this.filialOrg ? this.filialOrg.fullname : "",
                                filial_dueorder: this.filialOrg ? this.filialOrg.assignment : "",
                                service_center: this.serviceCenterTitleAddress,
                            },
                        )
                            .then((fileDoc) => {
                                this.$store.dispatch(
                                    "addRequestDoc",
                                    {
                                        file_name: fileDoc.name,
                                        mimetype: fileDoc.type,
                                        section: "requestDocs",
                                        blob: fileDoc,
                                        signers_count: signers[2].id ? 3 : 2,
                                        signer_iac_id: signers[0].id,
                                        signer_unit_id: signers[1].id,
                                        signer_final_id: signers[2].id,
                                        sign_type: signType,
                                    },
                                );
                                this.$q.notify({
                                    type: "notify-success",
                                    message: "Акт сформирован.<br>Помещен в раздел 'Документы'",
                                    html: true,
                                });
                            });
                    });
            }
        },
        createActCo8() {
            if (this.actIsPresentCO8()) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Акт уже сформирован.<br>Перейдите в раздел 'Документы'",
                    html: true,
                });
                return;
            }
            this.$q.dialog({
                component: DialogCreateActCO7,
                componentProps: {
                    actType: "CO-8",
                    signersCountByOrgKind: this.signersCountByOrgKind,
                },
            })
                .onOk(({
                    actDate,
                    signers,
                    noteAfter,
                    signType,
                }) => {
                    const requestDate = date.formatDate(
                        new Date(this.request.request_date_add),
                        "«DD» MMMM YYYY г.",
                        DATE_LOCALE_2,
                    );
                    const dateDocLong = date.formatDate(
                        date.extractDate(actDate, "DD.MM.YYYY"),
                        "«DD» MMMM YYYY г.",
                        DATE_LOCALE_2,
                    );
                    const reqWorksAll = this.reqWorks.map((rw) => rw.title)
                        .join(", ");

                    this.co8Number = `СР-${this.vncode}-${this.request.number}/${this.requestYear}`;
                    this.co8Date = date.formatDate(
                        date.extractDate(actDate, "DD.MM.YYYY"),
                        "YYYY-MM-DD",
                    );

                    let serviceCenter = "";
                    if (this.serviceRepairId) {
                        const sr = this.servicesRepairs.find(
                            (e) => e.id === this.serviceRepairId,
                        );
                        if (sr) {
                            serviceCenter = `${sr.title}, ${sr.address}, ${sr.telephone}`;
                        }
                    }

                    createDoc(
                        signers[2].id ? "act-co-8.docx" : "act-co-8-2.docx",
                        this.fileNameCO8,
                        {
                            act_number: this.co8Number,
                            act_date: dateDocLong,
                            act_org_ksa_title: this.getKsaTitle(this.request.unit_id),
                            act_org_title: this.getOrgProperty(
                                this.getOrgIdOfUnit(this.request.unit_id),
                                "name",
                            ),
                            act_org_address: this.unitAddress(this.request.unit_id),
                            act_org_vncode: this.vncode,
                            signer_iac_fio_full: this.getUserFIO(signers[0].id),
                            signer_iac_post: this.getUserPostTitle(signers[0].id),
                            signer_iac_dueorder: this.getUserDueOrder(signers[0].id) || signers[0].dueOrder,
                            signer_iac_fio_short: this.getUserFIOshort(signers[0].id),

                            signer_unit_post: this.getUserPostTitle(signers[1].id),
                            signer_unit_dueorder: this.getUserDueOrder(signers[1].id) || signers[1].dueOrder,
                            signer_unit_fio_short: this.getUserFIOshort(signers[1].id),

                            signer_final_fio_full: this.getUserFIO(signers[2].id) || this.getUserFIO(signers[1].id),
                            signer_final_post: this.getUserPostTitle(signers[2].id) || this.getUserPostTitle(signers[1].id),
                            signer_final_dueorder: this.getUserDueOrder(signers[2].id) ||
                                signers[2].dueOrder ||
                                this.getUserDueOrder(signers[1].id) ||
                                signers[1].dueOrder,
                            signer_final_fio_short: this.getUserFIOshort(signers[2].id) || this.getUserFIOshort(signers[1].id),

                            request_date: requestDate,
                            request_number: this.request.number,
                            request_text: this.request.note,
                            request_note: noteAfter || "",

                            tool_guaranty: this.toolGuarantyAndCO7,
                            tool_name: this.toolName,
                            tool_serial: this.tool ? this.tool.serial_number : "",
                            tool_inventar: this.tool ? this.tool.inventar_number : "",
                            request_works: reqWorksAll,
                            filial_dueorder: this.filialOrg ? this.filialOrg.assignment : "",
                            service_center: serviceCenter,
                        },
                    )
                        .then((fileDoc) => {
                            this.$store.dispatch(
                                "addRequestDoc",
                                {
                                    file_name: fileDoc.name,
                                    mimetype: fileDoc.type,
                                    section: "requestDocs",
                                    blob: fileDoc,
                                    signers_count: signers[2].id ? 3 : 2,
                                    signer_iac_id: signers[0].id,
                                    signer_unit_id: signers[1].id,
                                    signer_final_id: signers[2].id,
                                    sign_type: signType,
                                },
                            );
                            this.$q.notify({
                                type: "notify-success",
                                message: "Акт сформирован.<br>Помещен в раздел 'Документы'",
                                html: true,
                            });
                        });
                });
        },
        actCo41() {
            if (this.actIsPresentCO41()) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Акт уже сформирован.<br>Перейдите в раздел 'Документы'",
                    html: true,
                });
                return;
            }
            this.$q.dialog({
                component: DialogCreateActCO7,
                componentProps: {
                    actType: "CO-4.1",
                    toolDescription: this.toolDescription,
                    toolGuaranty: this.toolGuarantySimple,
                    signersCountByOrgKind: 2,
                },
            })
                .onOk(({
                    actDate,
                    signers,
                    noteCO41,
                    signType,
                }) => {
                    const dateDocLong = date.formatDate(
                        date.extractDate(actDate, "DD.MM.YYYY"),
                        // Date.now(),
                        "«DD» MMMM YYYY г.",
                        DATE_LOCALE_2,
                    );
                    this.co41Number = `СЦВ-${this.vncode}-${this.request.number}/${this.requestYear}`;
                    this.co41Date = date.formatDate(
                        date.extractDate(actDate, "DD.MM.YYYY"),
                        // Date.now(),
                        "YYYY-MM-DD",
                    );

                    createDoc(
                        "act-co-4.1.docx",
                        this.fileNameCO41,
                        {
                            act_number: this.co41Number,
                            act_date: dateDocLong,
                            act_org_ksa_title: this.getKsaTitle(this.request.unit_id),
                            act_org_title: this.getOrgProperty(
                                this.getOrgIdOfUnit(this.request.unit_id),
                                "name",
                            ),
                            co7_date: this.co7Date,
                            co7_number: this.co7Number,
                            act_org_address: this.unitAddress(this.request.unit_id),
                            act_org_vncode: this.vncode,
                            signer_iac_fio_full: this.getUserFIO(signers[0].id),
                            signer_iac_post: this.getUserPostTitle(signers[0].id),
                            signer_iac_dueorder: this.getUserDueOrder(signers[0].id) || signers[0].dueOrder,
                            signer_iac_fio_short: this.getUserFIOshort(signers[0].id),
                            signer_final_fio_full: this.getUserFIO(signers[2].id || signers[1].id),
                            signer_final_post: this.getUserPostTitle(signers[2].id || signers[1].id),
                            signer_final_dueorder: this.getUserDueOrder(
                                signers[2].id || signers[1].id,
                            ) ||
                                signers[2].dueOrder ||
                                signers[1].dueOrder,
                            signer_final_fio_short: this.getUserFIOshort(signers[2].id || signers[1].id),
                            act_content: noteCO41 || "",
                            service_center: this.serviceCenterTitleAddress,
                        },
                    )
                        .then((fileDoc) => {
                            this.$store.dispatch(
                                "addRequestDoc",
                                {
                                    file_name: fileDoc.name,
                                    mimetype: fileDoc.type,
                                    section: "requestDocs",
                                    blob: fileDoc,
                                    signers_count: signers[2].id ? 3 : 2,
                                    signer_iac_id: signers[0].id,
                                    signer_unit_id: signers[1].id,
                                    signer_final_id: signers[2].id,
                                    sign_type: signType,
                                },
                            );
                            this.$q.notify({
                                type: "notify-success",
                                message: "Акт сформирован.<br>Помещен в раздел 'Документы'",
                                html: true,
                            });
                        });
                });
        },
        actCo42() {
            if (this.actIsPresentCO42()) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Акт уже сформирован.<br>Перейдите в раздел 'Документы'",
                    html: true,
                });
                return;
            }
            this.$q.dialog({
                component: DialogCreateActCO7,
                componentProps: {
                    actType: "CO-4.2",
                    toolDescription: this.toolDescription,
                    toolGuaranty: this.toolGuarantySimple,
                    toolWorksList: this.reqWorks.map((w) => w.title).join(", "),
                    signersCountByOrgKind: 2,
                },
            })
                .onOk(({
                    actDate,
                    signers,
                    noteCO42,
                    signType,
                }) => {
                    const dateDocLong = date.formatDate(
                        date.extractDate(actDate, "DD.MM.YYYY"),
                        // Date.now(),
                        "«DD» MMMM YYYY г.",
                        DATE_LOCALE_2,
                    );

                    const co42Number = `СЦИ-${this.vncode}-${this.request.number}/${this.requestYear}`;

                    createDoc(
                        "act-co-4.2.docx",
                        this.fileNameCO42,
                        {
                            act_number: co42Number,
                            act_date: dateDocLong,
                            act_41_number: this.co41Number || "__________",
                            act_41_date: this.co41Date || "____",
                            act_org_ksa_title: this.getKsaTitle(this.request.unit_id),
                            act_org_title: this.getOrgProperty(
                                this.getOrgIdOfUnit(this.request.unit_id),
                                "name",
                            ),
                            co7_date: this.co7Date,
                            co7_number: this.co7Number,
                            act_org_address: this.unitAddress(this.request.unit_id),
                            act_org_vncode: this.vncode,
                            signer_iac_fio_full: this.getUserFIO(signers[0].id),
                            signer_iac_post: this.getUserPostTitle(signers[0].id),
                            signer_iac_dueorder: this.getUserDueOrder(signers[0].id) || signers[0].dueOrder,
                            signer_iac_fio_short: this.getUserFIOshort(signers[0].id),
                            signer_final_fio_full: this.getUserFIO(signers[2].id || signers[1].id),
                            signer_final_post: this.getUserPostTitle(signers[2].id || signers[1].id),
                            signer_final_dueorder: this.getUserDueOrder(
                                signers[2].id || signers[1].id,
                            ) ||
                                signers[2].dueOrder ||
                                signers[1].dueOrder,

                            signer_final_fio_short: this.getUserFIOshort(signers[2].id || signers[1].id),
                            act_content: noteCO42 || "",
                            service_center: this.serviceCenterTitleAddress,
                        },
                    )
                        .then((fileDoc) => {
                            this.$store.dispatch(
                                "addRequestDoc",
                                {
                                    file_name: fileDoc.name,
                                    mimetype: fileDoc.type,
                                    section: "requestDocs",
                                    blob: fileDoc,
                                    signers_count: signers[2].id ? 3 : 2,
                                    signer_iac_id: signers[0].id,
                                    signer_unit_id: signers[1].id,
                                    signer_final_id: signers[2].id,
                                    sign_type: signType,
                                },
                            );
                            this.$q.notify({
                                type: "notify-success",
                                message: "Акт сформирован.<br>Помещен в раздел 'Документы'",
                                html: true,
                            });
                        });
                });
        },
    },
};
</script>
