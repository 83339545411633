const ACCESS_LEVELS_VALUES = {
    FULL: 4,
    REGION: 3,
    UNIT: 2,
    OWN: 1,
};

module.exports.ACCESS_LEVELS_VALUES = ACCESS_LEVELS_VALUES;

const RIGHTS_CONSTANTS = {
    READ: 1,
    FULL: 2,
    OWN: 4,
    ALL: 8,
};

module.exports.RIGHTS_CONSTANTS = RIGHTS_CONSTANTS;

const RIGHTS_VALUES = {
    // admin
    RIGHT_SETTINGS: [RIGHTS_CONSTANTS.FULL],
    RIGHT_USER_STATUS_EDIT: [RIGHTS_CONSTANTS.FULL],

    // references
    RIGHT_REFERENCES: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REGIONS: [RIGHTS_CONSTANTS.FULL],
    RIGHT_ORGANIZATIONS: [RIGHTS_CONSTANTS.READ, RIGHTS_CONSTANTS.FULL],
    RIGHT_ORGANIZATIONS_KINDS: [RIGHTS_CONSTANTS.FULL],

    RIGHT_TOOLS_MODELS: [RIGHTS_CONSTANTS.FULL],
    RIGHT_TOOLS_KINDS: [RIGHTS_CONSTANTS.FULL],
    RIGHT_TOOLS_KINDS_TRAITS: [RIGHTS_CONSTANTS.FULL],
    RIGHT_TOOLS_SETS_KINDS: [RIGHTS_CONSTANTS.FULL],
    RIGHT_TOOLS_MANUFACTURERS: [RIGHTS_CONSTANTS.FULL],

    RIGHT_USERS_LIST: [RIGHTS_CONSTANTS.FULL],
    RIGHT_USERS_HL: [RIGHTS_CONSTANTS.READ, RIGHTS_CONSTANTS.FULL],
    RIGHT_USERS_POSTS: [RIGHTS_CONSTANTS.FULL],
    RIGHT_SERVICE_REPAIR_EDIT: [RIGHTS_CONSTANTS.FULL],
    RIGHT_PROGRAMS: [RIGHTS_CONSTANTS.READ, RIGHTS_CONSTANTS.FULL],
    RIGHT_REFERENCE_GAS_SUBSYSTEM: [RIGHTS_CONSTANTS.READ, RIGHTS_CONSTANTS.FULL],
    RIGHT_PROBLEMS_CATEGORIES: [RIGHTS_CONSTANTS.READ, RIGHTS_CONSTANTS.FULL],
    RIGHT_ACT_DISREPAIR_EDIT: [RIGHTS_CONSTANTS.FULL],

    // requests
    RIGHT_REQUEST_READ: [RIGHTS_CONSTANTS.OWN, RIGHTS_CONSTANTS.ALL],
    RIGHT_REQUEST_CREATE: [RIGHTS_CONSTANTS.OWN, RIGHTS_CONSTANTS.ALL],
    RIGHT_REQUEST_EDIT: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REQUEST_HOTLINE_EDIT: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REQUEST_DOCS_DELETE: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REQUEST_DELETE: [RIGHTS_CONSTANTS.FULL],
    RIGHT_HOTLINE_EDIT: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REQUEST_MULTIPLE: [RIGHTS_CONSTANTS.FULL],
    RIGHT_SET_ENGINEER_EMAIL: [RIGHTS_CONSTANTS.FULL],

    // tools ans zip
    RIGHT_TOOLS_READ: [RIGHTS_CONSTANTS.OWN, RIGHTS_CONSTANTS.ALL],
    RIGHT_TOOLS_WRITE: [RIGHTS_CONSTANTS.FULL],
    RIGHT_ZIP: [RIGHTS_CONSTANTS.READ, RIGHTS_CONSTANTS.FULL],
    RIGHT_TOOL_PLACE_EDIT: [RIGHTS_CONSTANTS.FULL],

    // reports
    RIGHT_REPORT_FORMULAR: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_ENCLOSURE_1: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_ENCLOSURE_2: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_REQUESTS_ARCHIEVE: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_REPAIRS_1: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_REPAIRS_2: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_REPAIRS_3: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_REPAIRS_SHORT: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_REPAIRS_WAITING: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_FAILURES: [RIGHTS_CONSTANTS.FULL],
    RIGHT_REPORT_SUBSYSTEM: [RIGHTS_CONSTANTS.FULL],

    // documents signers
    RIGHT_SIGNING_DOCS_IAC: [RIGHTS_CONSTANTS.FULL],
    RIGHT_SIGNING_DOCS_UNIT: [RIGHTS_CONSTANTS.FULL],
    RIGHT_SIGNING_DOCS_FINAL: [RIGHTS_CONSTANTS.FULL],

    // others
    RIGHT_VIEW_COST: [RIGHTS_CONSTANTS.FULL],
};

class Right {
    constructor(key, title, category = null) {
        this.key = key;
        this.category = category;
        this.right = RIGHTS_VALUES[key];
        this.title = title;
    }
}

module.exports.RIGHTS = [
    new Right(
        "RIGHT_SETTINGS",
        [
            "Общие настройки приложения,",
            "Управление пользователями и ролями (создание, редактирование, удаление),",
        ].join(" "),
        "Администрирование"
    ),
    new Right(
        "RIGHT_USER_STATUS_EDIT",
        "Управление статусом учетной записи"
    ),
    // references
    new Right(
        "RIGHT_REFERENCES",
        "Просмотр и редактирование справочников",
        "Справочники"
    ),
    new Right(
        "RIGHT_REGIONS",
        "Регионы"
    ),
    new Right(
        "RIGHT_ORGANIZATIONS",
        "Организации и Балансодержатели"
    ),
    new Right(
        "RIGHT_ORGANIZATIONS_KINDS",
        "Типы организаций"
    ),
    new Right(
        "RIGHT_TOOLS_MODELS",
        "Модели учетных единиц"
    ),
    new Right(
        "RIGHT_TOOLS_KINDS",
        "Виды учетных единиц"
    ),
    new Right(
        "RIGHT_TOOLS_KINDS_TRAITS",
        "Характеристики видов учетных единиц"
    ),
    new Right(
        "RIGHT_TOOLS_SETS_KINDS",
        "Виды комплектов учетных единиц"
    ),
    new Right(
        "RIGHT_TOOLS_MANUFACTURERS",
        "Производители оборудования"
    ),
    new Right(
        "RIGHT_REFERENCE_GAS_SUBSYSTEM",
        "Подсистемы ГАС \"Правосудие\""
    ),
    new Right(
        "RIGHT_PROGRAMS",
        "Программное обеспечение"
    ),
    new Right(
        "RIGHT_ACT_DISREPAIR_EDIT",
        "Неисправности ПТС"
    ),
    new Right(
        "RIGHT_PROBLEMS_CATEGORIES",
        "Категории проблем"
    ),
    new Right(
        "RIGHT_USERS_LIST",
        "Учетные записи"
    ),
    new Right(
        "RIGHT_USERS_POSTS",
        "Должности"
    ),
    new Right(
        "RIGHT_USERS_HL",
        "Исполнители ГЛ"
    ),
    new Right(
        "RIGHT_SERVICE_REPAIR_EDIT",
        "Сервисные центры"
    ),
    // requests
    new Right(
        "RIGHT_REQUEST_READ",
        "Просмотр заявок",
        "Заявки"
    ),
    new Right(
        "RIGHT_REQUEST_CREATE",
        "Создание заявок"
    ),
    new Right(
        "RIGHT_REQUEST_EDIT",
        "Редактирование заявок"
    ),
    new Right(
        "RIGHT_REQUEST_HOTLINE_EDIT",
        "Редактирование заявок ГЛ"
    ),
    new Right(
        "RIGHT_REQUEST_DOCS_DELETE",
        "Удаление документов из заявок"
    ),
    new Right(
        "RIGHT_REQUEST_DELETE",
        "Закрытие, возврат на повторное исполнение, удаление заявок"
    ),
    new Right(
        "RIGHT_HOTLINE_EDIT",
        "Регистрация заявок на горячей линии"
    ),
    new Right(
        "RIGHT_REQUEST_MULTIPLE",
        "Создание группы заявок"
    ),
    new Right(
        "RIGHT_SET_ENGINEER_EMAIL",
        "Настройка отправки сообщений инженеру"
    ),
    // tools and zip
    new Right(
        "RIGHT_TOOLS_READ",
        "Просмотр списка оборудования",
        "Оборудование и ЗИП"
    ),
    new Right(
        "RIGHT_TOOLS_WRITE",
        "Внесение изменений в список оборудования"
    ),
    new Right(
        "RIGHT_ZIP",
        "Комплекты ЗИП"
    ),
    new Right(
        "RIGHT_TOOL_PLACE_EDIT",
        "Редактирование местоположения оборудования"
    ),
    // reports
    new Right(
        "RIGHT_REPORT_FORMULAR",
        "Табличная часть Формуляра",
        "Отчеты"
    ),
    new Right(
        "RIGHT_REPORT_ENCLOSURE_1",
        "Приложение №1"
    ),
    new Right(
        "RIGHT_REPORT_ENCLOSURE_2",
        "Приложение №2"
    ),
    new Right(
        "RIGHT_REPORT_REQUESTS_ARCHIEVE",
        "Журнал учета заявок в архив"
    ),
    new Right(
        "RIGHT_REPORT_REPAIRS_1",
        "Отчет по ремонтам №1"
    ),
    new Right(
        "RIGHT_REPORT_REPAIRS_2",
        "Отчет по ремонтам №2"
    ),
    new Right(
        "RIGHT_REPORT_REPAIRS_3",
        "Отчет по ремонтам №3"
    ),
    new Right(
        "RIGHT_REPORT_REPAIRS_SHORT",
        "Краткая таблица по ремонтам ПТС"
    ),
    new Right(
        "RIGHT_REPORT_REPAIRS_WAITING",
        "Отчет о количестве единиц ПТС ожижающих ремонта"
    ),
    new Right(
        "RIGHT_REPORT_FAILURES",
        "Форма отчета по отказам ПТС"
    ),
    new Right(
        "RIGHT_REPORT_SUBSYSTEM",
        "Отчет по подсистемам ГАС «Правосудие»"
    ),

    // documents signers
    new Right(
        "RIGHT_SIGNING_DOCS_IAC",
        "Подписание документов от филиала",
        "Документы"
    ),
    new Right(
        "RIGHT_SIGNING_DOCS_UNIT",
        "Подписание документов от объекта"
    ),
    new Right(
        "RIGHT_SIGNING_DOCS_FINAL",
        "Подписание документов от УСД"
    ),
    new Right(
        "RIGHT_VIEW_COST",
        "Отображение поля 'Стоимость'"
    ),
];


module.exports.REQUEST_KINDS_VALUES = {
    SOFT_SPECIAL: 4,    // Заявка по вопросам СПО
    SOFT_COMMON: 1,     // Заявка по вопросам ОПО
    REPAIR_HARD: 2,     // Заявка на ремонт оборудования
    LEARNING: 5,        // Заявка на обучение пользователей
    RESTORE: 7,         // Заявка на восстановление системы
    TASK: 8,            // Поручение
    MISTAKE: 9,         // Ошибочно поданная заявка
    REVOKE: 10,         // Отозванная заявка
    CONSULTATION: 11,   // Заявка на консультацию
    PROPOSE: 12,        // Предложение по модификации
};

module.exports.REQUEST_STATUS_VALUES = {
    UNDEFINED: 0,               // Неустановлено
    WAITING_ENGINEER: 2,        // Ожидание исполнителя
    PERFORMING: 3,              // На исполнении
    PERFORMED: 4,               // Исполнена
    CLOSED: 7,                  // Закрыта
    CANCELED: 8,                // Отменена
    HOT_LINE_WAITING: 9,        // Ожидание исполнителя ГЛ
    HOT_LINE_PERFORMING: 10,    // На исполнении ГЛ
    HOT_LINE_PERFORMED: 11,     // Исполнено ГЛ
};

module.exports.OPTIONS_VIEW_BEFORE_VALUES = {
    REPAIR_WAITING: 1,
    TO_DISMISS: 3,
    NOT_COMPLETED: 2,
};

module.exports.TOOLS_STATES_VALUES = {
    WAITING: 5,
    IN_WORK: 1,
    BROKEN: 2,
    WAITING_UTILIZE: 3,
    UTILIZED: 4,
};

const REPAIRS_TYPES_VALUES = {
    GUARANTY: 1,
    SERVICE_CONTRACT: 2,
    SERVICE_MONEY: 3,
    FILIAL_MONEY: 4,
    FILIAL_SPARE: 5,
    FILIAL_HAND: 6,
};

module.exports.REPAIRS_TYPES_VALUES = REPAIRS_TYPES_VALUES;

module.exports.REPAIRS_TYPES = [
    { value: REPAIRS_TYPES_VALUES.GUARANTY, label: "Гарантийный ремонт" },
    { value: REPAIRS_TYPES_VALUES.SERVICE_CONTRACT, label: "Ремонт силами СЦ по договору (аукцион)" },
    { value: REPAIRS_TYPES_VALUES.SERVICE_MONEY, label: "Ремонт силами СЦ за наличный расчет" },
    { value: REPAIRS_TYPES_VALUES.FILIAL_MONEY, label: "Ремонт силами филиала за наличный расчет" },
    { value: REPAIRS_TYPES_VALUES.FILIAL_SPARE, label: "Ремонт силами филиала посредством ЗИП" },
    { value: REPAIRS_TYPES_VALUES.FILIAL_HAND, label: "Ремонт силами филиала без траты денежных средств" },
];

module.exports.PERFORM_KIND = {
    SENIOR: 1,
    FILIAL: 2,
};

module.exports.CONTACT_KIND = {
    FROM_IAC: 1,
    STRANGER: 2,
};

module.exports.PERFORM_TYPES_IDS = {
    WITHOUT_VISIT: 1,
    WITH_VISIT: 2,
};

module.exports.REPAIRS_RESULTS_IDS = {
    FULL: 1, // Ремонт выполнен полностью
    PART: 2, // Ремонт выполнен частично
    NONE: 3, // Ремонт не выполнен
};

const INNER_REQS_STATES_VALUES = {
    FULFILLED: 1,
    DECLINED: 2,
    NEED_INFO: 3,
};

module.exports.INNER_REQS_STATES_VALUES = INNER_REQS_STATES_VALUES;

module.exports.INNER_REQS_STATES = [
    { id: INNER_REQS_STATES_VALUES.FULFILLED, label: "Запрос исполнен" },
    { id: INNER_REQS_STATES_VALUES.DECLINED, label: "Запрос отклонен" },
    { id: INNER_REQS_STATES_VALUES.NEED_INFO, label: "Запрошена дополнительная информация" },
];

const UTC_OFFSET = {
    1: 3,   // Республика Адыгея (Адыгея) UTC+3
    2: 5,   // Республика Башкортостан UTC+5
    3: 8,   // Республика Бурятия UTC+8
    4: 7,   // Республика Алтай UTC+7
    5: 3,   // Республика Дагестан UTC+3
    6: 3,   // Республика Ингушетия UTC+3
    7: 3,   // Кабардино-Балкарская Республика UTC+3
    8: 3,   // Республика Калмыкия UTC+3
    9: 3,   // Карачаево-Черкесская Республика UTC+3
    10: 3,  // Республика Карелия UTC+3
    11: 3,  // Республика Коми UTC+3
    12: 3,  // Республика Марий Эл UTC+3
    13: 3,  // Республика Мордовия UTC+3
    14: 9,  // Республика Саха (Якутия) UTC+9
    15: 3,  // Республика Северная Осетия - Алания UTC+3
    16: 3,  // Республика Татарстан (Татарстан) UTC+3
    17: 7,  // Республика Тыва UTC+7
    18: 4,  // Удмуртская Республика UTC+4
    19: 7,  // Республика Хакасия UTC+7
    20: 3,  // Чеченская Республика UTC+3
    21: 3,  // Чувашская Республика - Чувашия UTC+3
    22: 7,  // Алтайский край UTC+7
    23: 3,  // Краснодарский край UTC+3
    24: 7,  // Красноярский край UTC+7
    25: 10, // Приморский край UTC+10
    26: 3,  // Ставропольский край UTC+3
    27: 10, // Хабаровский край UTC+10
    28: 9,  // Амурская область UTC+9
    29: 3,  // Архангельская область UTC+3
    30: 4,  // Астраханская область UTC+4
    31: 3,  // Белгородская область UTC+3
    32: 3,  // Брянская область UTC+3
    33: 3,  // Владимирская область UTC+3
    34: 3,  // Волгоградская область UTC+3
    35: 3,  // Вологодская область UTC+3
    36: 3,  // Воронежская область UTC+3
    37: 3,  // Ивановская область UTC+3
    38: 8,  // Иркутская область UTC+8
    39: 2,  // Калининградская область UTC+2
    40: 3,  // Калужская область UTC+3
    41: 12, // Камчатский край UTC+12
    42: 7,  // Кемеровская область UTC+7
    43: 3,  // Кировская область UTC+3
    44: 3,  // Костромская область UTC+3
    45: 5,  // Курганская область UTC+5
    46: 3,  // Курская область UTC+3
    47: 3,  // Ленинградская область UTC+3
    48: 3,  // Липецкая область UTC+3
    49: 11, // Магаданская область UTC+11
    50: 3,  // Московская область UTC+3
    51: 3,  // Мурманская область UTC+3
    52: 3,  // Нижегородская область UTC+3
    53: 3,  // Новгородская область UTC+3
    54: 7,  // Новосибирская область UTC+7
    55: 6,  // Омская область UTC+6
    56: 5,  // Оренбургская область UTC+5
    57: 3,  // Орловская область UTC+3
    58: 3,  // Пензенская область UTC+3
    59: 5,  // Пермский край UTC+5
    60: 3,  // Псковская область UTC+3
    61: 3,  // Ростовская область UTC+3
    62: 3,  // Рязанская область UTC+3
    63: 4,  // Самарская область UTC+4
    65: 11, // Сахалинская область UTC+11
    66: 5,  // Свердловская область UTC+5
    67: 3,  // Смоленская область UTC+3
    68: 3,  // Тамбовская область UTC+3
    69: 3,  // Тверская область UTC+3
    70: 7,  // Томская область UTC+7
    71: 3,  // Тульская область UTC+3
    72: 5,  // Тюменская область UTC+5
    73: 4,  // Ульяновская область UTC+4
    74: 5,  // Челябинская область UTC+5
    75: 9,  // Забайкальский край UTC+9
    76: 3,  // Ярославская область UTC+3
    77: 3,  // г. Москва UTC+3
    78: 3,  // г. Санкт-Петербург UTC+3
    79: 10, // Еврейская автономная область UTC+10
    83: 3,  // Ненецкий автономный округ UTC+3
    86: 5,  // Ханты-Мансийский автономный округ - Югра UTC+5
    87: 12, // Чукотский автономный округ UTC+12
    89: 5,  // Ямало-Ненецкий автономный округ UTC+5
    91: 3,  // Республика Крым UTC+3
    92: 3,  // г. Севастополь UTC+3
};

module.exports.UTC_OFFSET = UTC_OFFSET;